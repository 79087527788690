import React, { useCallback, useEffect } from 'react';
import { atom, useSetRecoilState } from 'recoil';
import * as _ from 'lodash';

interface Props {
  isTop: boolean,
  isBottom: boolean,
  isUp: boolean,
  isDown: boolean,
  scrollY: number,
}

export const isTopState = atom<Props>({
  key: 'isTopState',
  default: {
    isTop: true,
    isBottom: false,
    isUp: false,
    isDown: false,
    scrollY: 0,
  },
});

let preScrollY = 0;

export function ScrollFrame() {
  const setIsTop = useSetRecoilState(isTopState);
  const isBottom = useCallback(() =>
    (window.innerHeight + window.pageYOffset) >= (document.body.offsetHeight - 396),
    [window.innerHeight, window.pageYOffset, document.body.offsetHeight]
  );

  const up = useCallback(() => {
    const data = {
      isTop: (window.pageYOffset ?? 0) <= 10,
      isBottom: isBottom(),
      isUp: true,
      isDown: false,
      scrollY: window.pageYOffset,
    };

    setIsTop(data);
  }, [setIsTop, isBottom]);

  const down = useCallback(() => {
    const data = {
      isTop: (window.pageYOffset ?? 0) <= 10,
      isBottom: isBottom(),
      isUp: false,
      isDown: true,
      scrollY: window.pageYOffset,
    };

    setIsTop(data);
  }, [setIsTop, isBottom]);

  const scrollHandler = useCallback(() => {
      const { pageYOffset: scrollY } = window;
      preScrollY < scrollY ? down() : up();
      preScrollY = scrollY;
    },
    [down, up]
  );


  useEffect(() => {
    setIsTop(value => value);
  }, [setIsTop]);

  useEffect(() => {
    document.addEventListener('scroll', _.throttle(scrollHandler, 5));
    return () => {
      document.removeEventListener('scroll', _.throttle(scrollHandler, 5));
    };
  }, [scrollHandler]);

  return (<></>);
}
