import uniqid from 'uniqid';
import { atom } from 'jotai';
import { atomWithStorage, RESET } from 'jotai/utils';

import { TypicalPhoneFormatPolicy } from '../../shared/domain/PhoneFormatPolicy';
import { getMobileType } from '../../shared/helper/DeviceHelper';

interface IRequestForm {
  requestId: string;
  name: string;
  phone: string;
  question: string;
  isPrivacyPolicyChecked: boolean;
  bizType: string;
  requestType: string;
  type: string;
}

export const requestRocketAtom = atomWithStorage<IRequestForm>(
  'request-rocket-form',
  {
    requestId: uniqid(),
    name: '',
    phone: '',
    question: '',
    isPrivacyPolicyChecked: true,
    bizType: '',
    requestType: '',
    type: getMobileType(),
  });

export const requestRocketState = atom(
  (get) => get(requestRocketAtom),
  (get, set, newValue: Partial<IRequestForm> | typeof RESET) => {
    if (newValue === RESET) {
      set(requestRocketAtom, {
        requestId: uniqid(),
        name: '',
        phone: '',
        question: '',
        isPrivacyPolicyChecked: true,
        bizType: '',
        requestType: '',
        type: getMobileType(),
      });
      return;
    }
    const phoneFormatPolicy = new TypicalPhoneFormatPolicy();
    const defaultValue = get(requestRocketAtom);
    if (newValue.phone != null) {
      newValue.phone = phoneFormatPolicy.changeFormat(newValue.phone);
    }
    set(requestRocketAtom, { ...defaultValue, ...newValue });
  },
);
