export const mergeStyle = (a: any, b: any) => Object.assign({}, a, b);

export const deskSize14 = '.72vw';

export const deskSize15 = '.78vw';

export const deskSize16 = '.83vw';

export const deskSize17 = '.88vw';

export const deskSize18 = '.93vw';

export const deskSize19 = '.98vw';

export const deskSize20 = '1.04vw';

export const deskSize22 = '1.15vw';

export const deskSize23 = '1.2vw';

export const deskSize28 = '1.46vw';

export const deskSize38 = '1.97vw';

export const deskSize40 = '2.08vw';

export const deskSize50 = '2.6vw';

export const mobileSize10 = '2.5vw';

export const mobileSize11 = '2.65vw';

export const mobileSize11_5 = '2.77vw';

export const mobileSize12 = '2.89vw';

export const mobileSize13 = '3.14vw';

export const mobileSize14 = '3.38vw';

export const mobileSize15 = '3.62vw';

export const mobileSize16 = '3.86vw';

export const mobileSize17 = '4.1vw';

export const mobileSize18 = '4.34vw';

export const mobileSize19 = '4.58vw';

export const mobileSize20 = '4.83vw';

export const mobileSize24 = '5.8vw';

export const mobileSize30 = '7.24vw';

export const mobileSize85 = '20.53vw';

export const spoqa = 'Spoqa';

export const robotoBlack = 'RobotoBlack';

export const robotoRegular = 'RobotoRegular';

export const robotoMedium = 'RobotoMedium';

export const robotoThin = 'RobotoThin';

export const rixExtraBold = 'RixMGo_Pro-ExtraBold';

export const rixMedium = 'RixMGo_Pro-Medium';

export const rixRegular = 'RixMGo_Pro-Regular';

export const gothamThin = 'Gotham-Thin';

export const pretendard = 'Pretendard';
