import { css } from 'aphrodite';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { spoqa } from '../../../../shared/css/css-utils';
import { SvgImage } from '../../../../shared/css/SvgImage';

export function InputButtonItem(input: {
  imageSrc: any,
  title: string,
  before: string,
  after: string,
  detail: string,
  onClick: () => void,
}) {
  const {isMobile, isTablet} = useRecoilValue(totalScreenState);
  const styles = Styles(isMobile, isTablet);
  return (
    <div
      className={css(styles.wrapper)}
      onClick={input.onClick}
    >
      <div
        className={css(styles.box)}
      >
        <div
          className={css(styles.circle)}
        >
          <SvgImage
            className={css(styles.image)}
            src={input.imageSrc}
            alt={'money'}
          />
        </div>
        <div
          className={css(styles.title)}
        >
          {input.title}
        </div>
        <div className={css(styles.discountBox)}>
          <div className={css(styles.discountBox_first)}>
            {input.before}
          </div>
          <div className={css(styles.discountBox_second)}>
            {input.after}
          </div>
        </div>
        <div className={css(styles.detail)}>
          {input.detail}
        </div>
      </div>
    </div>
  );
}

const Styles = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  wrapper: {
    width: '174px/162px@199px',
    height: '186px/173px@213px',

    ':hover': {
      ':nth-child(1n) > div': {
        border: '1.5px solid #FF5D00',
        cursor: 'pointer',
        transition: 'all 0.3s',
        transform: '/translateY(-5px)',
      }
    }
  },
  box: {
    border: '1px solid #E7E7E7',
    borderRadius: '10px/9px@11px',

    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '24px/24px@28px',
    paddingBottom: '16px/12px@25px',

    fontFamily: spoqa,
    color: '#333333',
  },
  circle: {
    width: '48px/45px@54px',
    height: '48px/45px@54px',
    borderRadius: '50%',

    background: 'rgba(255, 93, 0, 0.1)',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '25px/23px@29px',
    height: '30px/@35px',
  },
  title: {
    fontWeight: 500,
    paddingTop: '12px/13px@20px',
    fontSize: '18px/17px@20px',
    lineHeight: '30px/28px@25px',
  },
  discountBox: {
    display: 'flex',
    paddingTop: '/@7px',
    paddingBottom: '/@6px',
    // marginBottom: '/-5px',
  },
  discountBox_first: {
    textDecoration: 'line-through',
    textDecorationThickness: '/1px',
    transform: '/translateY(1px)@translateY(5.5px)',

    fontSize: '12px/11px@14px',
    lineHeight: '30px/28px@18px',
    marginRight: '5px',
  },
  discountBox_second: {
    // height: '30px/28px',

    fontWeight: 'bold',
    fontSize: '20px/18px@23px',
    lineHeight: '30px/28px@29px',

    letterSpacing: '0.5px',
  },
  detail: {
    color: '#BDBDBD',
    fontSize: '12px/11px@14px',
    lineHeight: '25px/20px@18px',
    height: '25px/20px@18px',
  }
}, isMobile, isTablet);
