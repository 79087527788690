import { PaymentBuyer } from './PaymentBuyer';

export interface IPaymentInput {
  name: string;
  amount: number;
  buyer: PaymentBuyer;
  redirect: string;
  callback?: (values: any) => void;
  merchantId: string;
  payMethod?: string;
  buyer_tel?: string;
}

export const PAYMENT_SERVICE = Symbol('PAYMENT_SERVICE');

export interface Payment {
  request(props: IPaymentInput): Promise<any>;
}
