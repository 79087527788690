export class AddTaxagentRequestDto {
  constructor(
    public readonly name: string,
    public readonly phone: string,
    public readonly customerType: string,
    public readonly declareType: string[],
    public readonly noticeFiles: string[],
    public readonly otherFiles: string[],
    public readonly recommendCode: string,
    public readonly etcInfo: string,
    public readonly code: string,
    public readonly keyword: string,
  ) {
  }
}

export interface IRequest {
  id: string,
  phone: string,
  name: string,
  code: string,
}

export interface IBasicInfo {
  name: string,
  phone: string,
  declareType: string[],
  isDependents: boolean,
  recommendCode: string,
  isLateTimeContact: boolean,
  code: string,
  keyword: string,
}

export interface IBasicInfoUpdate {
  id: string,
  declareType: string[],
  isDependents: boolean,
  recommendCode: string,
  isLateTimeContact: boolean,
}

export interface IHometaxKakaoLoginInfo {
  id: string,
  personalNumber: string,
}

export interface IHometaxLoginInfo {
  id: string,
  homeTaxId: string,
  homeTaxPassword: string,
}

export interface IUploadFileInfo {
  id: string,
  files: string[],
}

export interface IAddInfo {
  code: string,
  name: string,
  phone: string,
  bankName: string,
  accountNumber: string,
  depositorName: string,
  email: string,
  personalExemptionInfo: string,
  otherFiles: string[],
  note: string,
}

export interface IAddInfoPersonal {
  code: string,
  name: string,
  phone: string,
  bankName: string,
  accountNumber: string,
  depositorName: string,
  email: string,
  personalExemptionInfo: string,
  laborCost: number,
  rent: number,
  taxAdjustmentFiles: string[],
  otherFiles: string[],
  note: string,
}

export interface IAddInfoFile {
  id: string,
  otherFiles: string[],
}

export const TAX_AGENT_REQUEST_REPOSITORY = Symbol('TAX_AGENT_REQUEST_REPOSITORY');

export interface TaxagentRequestRepository {
  addTaxagentRequest(request: AddTaxagentRequestDto): Promise<string>
  addTaxagentRequestBasicInfo(request: IBasicInfo): Promise<string>;
  updateTaxagentRequestBasicInfo(request: IBasicInfoUpdate): Promise<string>;
  updateTaxagentHomeTaxKakaoLoginInfo(request: IHometaxKakaoLoginInfo): Promise<string>;
  updateTaxagentHomeTaxLoginInfo(request: IHometaxLoginInfo): Promise<string>;
  updateTaxagentUploadFileInfo(request: IUploadFileInfo): Promise<string>;
  updateTaxagentRequestAddInfo(request: IAddInfo): Promise<string>;
  updateTaxagentRequestAddInfoPersonal(request: IAddInfoPersonal): Promise<string>;
  updateTaxagentRequestAddInfoFile(request: IAddInfoFile): Promise<string>;
  findOneRequestWithBasicInfo(phone: string, name: string): Promise<IRequest | undefined>;
  findOneRequestWithRequestId(id: string): Promise<IRequest | undefined>;
}
