import { css } from 'aphrodite';
import React, { useEffect } from 'react';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { useRecoilValue } from 'recoil';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { rocketContentBlack, rocketOrange } from '../../../assets/ColorContants';
import { PriceItems } from './PriceItems';
import { animationStyle } from '../../../shared/SharedAni';
import { stepViewAnimationStyle } from './RequestInput';
import { useInjection } from '../../../../shared/presentation/InversifyProvider';
import { SCRIPT_LOGGER, ScriptLogger } from '../../../../shared/domain/ScriptLogger';

export function Step01() {
  const {isMobile, isTablet} = useRecoilValue(totalScreenState);
  const styles = Styles({isMobile, isTablet});

  const scriptLogger = useInjection<ScriptLogger>(SCRIPT_LOGGER);

  useEffect(() => {
    scriptLogger?.sendPathApproachedLog('/request/0');
  }, [])

  return (
    <div
      className={css(
        stepViewAnimationStyle,
        animationStyle(0, 0).popUp
      )}
    >
      <div className={css(
        styles.description,
        animationStyle(0, 0).popUp
      )}>
        <span className={css(styles.orange)}>30초</span>만에 로켓신청하세요 🚀
      </div>
      <PriceItems/>
    </div>
  );
}

const Styles = ({isMobile, isTablet}: { isMobile: boolean, isTablet: boolean }) => CreateStyle({
  description: {
    fontSize: '16px/18px@18px',
    lineHeight: '30px/30px@31px',
    marginBottom: '39px/41px@32px',
    color: rocketContentBlack,
  },
  orange: {
    color: rocketOrange,
    fontWeight: 'bold',
  },
}, isMobile, isTablet);
