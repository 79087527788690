import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { ReactComponent as SuccessCircle } from '../../../assets/svgs/success-circle.svg';
import { RocketButton } from '../../../shared/RocketButton';
import { defaultPage, useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { rocketContentBlack } from '../../../assets/ColorContants';
import { MobileRequestHeader } from '../../../shared/header/MobileRequestHeader';
import { stepViewAnimationStyle } from './RequestInput';
import { animationStyle } from '../../../shared/SharedAni';
import { Header } from '../../../shared/header/Header';
import { useInjection } from '../../../../shared/presentation/InversifyProvider';
import { SCRIPT_LOGGER, ScriptLogger } from '../../../../shared/domain/ScriptLogger';

export function RequestSuccessPage() {
  const { isMobile, isTablet, isDesktop } = useRecoilValue(totalScreenState);
  const history = useMyHistory();
  const scriptLogger = useInjection<ScriptLogger>(SCRIPT_LOGGER);

  useEffect(() => {
    scriptLogger?.sendPathApproachedLog('/request_success');
  }, [])

  const styles = Styles({ isMobile, isTablet });
  return (
    <div
      id={'request_success'}
      className={css(stepViewAnimationStyle, animationStyle(0,0).popUp)}>
      {isDesktop ? <Header noRequest/> : <MobileRequestHeader/>}
      <div className={css(styles.background)}>
        <SuccessCircle className={css(styles.circle)}/>
        <div className={css(styles.title)}>
          법인설립 신청완료
        </div>
        <div className={css(styles.description)}>
          신청이 완료되었습니다.<br/>
          담당자가 신속히 연락드릴게요 ☺️
        </div>
        <RocketButton
          width={'332px/264px@380px'}
          height={'64px/52px@68px'}
          borderRadius={'10px/9px@12px'}
          marginBottom={'33px/31px@42px'}
          fontSize={'18px/16px@19px'}
          onClick={() => history.push(defaultPage)}
          id={'return_main'}
        >
          메인으로 돌아가기
        </RocketButton>
        <div className={css(styles.time)}>
          연락 소요시간 : 평균 15분 이내
        </div>
      </div>
    </div>
  );
}

const Styles = ({ isMobile, isTablet }: { isMobile: boolean, isTablet: boolean }) => CreateStyle({
  background: {
    width: '100%',
    background: 'white',

    paddingTop: '136px/220px@186px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    fontFamily: 'Spoqa',
    fontSize: '26px/26px@32px',
    color: rocketContentBlack,
    //styleName: desktop/H/4;
    lineHeight: '43px/40px@62px',
  },
  circle: {
    width: '50px/50px@60px',
    height: '50px/50px@60px',
    marginBottom: '32px/49px@36px',
  },
  title: {
    marginBottom: '13px/12px@18px',
    fontWeight: 'bold',
  },
  description: {
    fontSize: '16px/18px@18px',
    marginBottom: '167px/68px@204px',
    textAlign: 'center',
    lineHeight: '30px/30px@30px',

    color: '#242424',
  },
  time: {
    fontWeight: 'normal',
    fontSize: '14px/16px@18px',
    lineHeight: '27px/27px@38px',

    color: '#9D9D9D',
  },
}, isMobile, isTablet);
