import { StyleSheet } from 'aphrodite';
import { CreateStyle } from '../presentation/shared/CreateStyle';

export const sharedColor = StyleSheet.create({
  baseColor: {
    color: process.env.REACT_APP_BASE_COLOR ?? 'black',
  },
  greenBlue: {
    color: '#06b3cb',
  },
  greenBlue2: {
    color: '#00abd4'
  },
  highlightBlue: {
    color: '#00b1d1',
  },
  deepBlue: {
    color: '#008fb1'
  },
  brightBlue: {
    color: '#5e8ec0',
  },
  darkBlue: {
    color: '#1b315d',
  },
  taxBlue: {
    color: '#1b1d91',
  },
  grayBlue: {
    color: '#8a8bb7',
  },
  blueGray: {
    color: '#838ba3',
  },
  nightBlue: {
    color: '#051a43',
  },
  newbizGreen: {
    color: '#70af00'
  },
  navy: {
    color: '#002933',
  },
  lightBlue: {
    color: '#9cbee1',
  },
  white: {
    color: 'white'
  },
  lightGray: {
    color: '#d3d3d3',
  },
  darkGray: {
    color: '#34373a',
  },
  gray: {
    color: '#e7e7e6',
  },
  partnerGray: {
    color: '#b8a891',
  },
  textGray: {
    color: '#202b40',
  },
  black: {
    color: 'black',
    fill: 'black',
  },
  darkBlueBack: {
    backgroundColor: '#002933'
  },
  darkBlueFaintBack: {
    backgroundColor: 'rgba(5, 26, 67, .8)'
  },
  transparentBack: {
    backgroundColor: 'transparent'
  },
});

export const sharedShape = StyleSheet.create({
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: -2,
    objectFit: 'cover',
  },
  flexStyle: {
    display: 'flex',
  },
  reverse: {
    transform: 'rotate(180deg)',
  },
  rotate90: {
    transform: 'rotate(90deg)',
  },
  lineThrough: {
    textDecoration: 'line-through',
  },
  break: {
    flexBasis: '100vw',
    height: 0,
  },
  default: {
  }
})

const SharedStyleSheet = (isMobile: boolean, isTablet?: boolean) => CreateStyle({
  onlyDesktop: isMobile || isTablet ? { display: 'none' } : {},
  onlyMobile: isMobile || isTablet ? {} : { display: 'none'},
  onlyPhone: isMobile ? {} : { display: 'none'},
  onlyTablet: isTablet ? {} : { display: 'none'},
  onlyWideDevice: !isMobile ? {} : { display: 'none'},
  iconSpace: {
    marginLeft: '/15px',
  },
  mainVideo: {
    display_: 'none/',
    position: 'absolute',
    transform: 'translateX(-50%) translateY(-50%)',

    left: '50%',
    top: '50%',

    minWidth: '100%',
    minHeight: '100%',
    zIndex: -1,
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: -1,

  },
  video: {
    display_: 'none/',
    position: 'absolute',
    transform: 'translateX(-50%) translateY(-50%)',

    left: '50%',
    top: '50%',

    minWidth: '100%',
    minHeight: '100%',
    zIndex: -1,
  },
  closeButton: {
    width: '15px/20px',
    height: '15px/20px',
    backgroundColor: 'rgba(0, 0, 0, 0)',

    cursor: 'pointer',

    position: 'absolute',
    right: '3vw/1vw',
    top: '3vw/1vw',
    zIndex: 5,
    fill_: 'white/black',
  },
  black: {
    fill: 'black',
  },
}, isMobile, isTablet);

export default SharedStyleSheet;
