export interface PaymentBank {
  bankName: string;
  accountNo: string;
  accountOwner: string;
}

export const PAYMENT_BANK_REPOSITORY = Symbol('PAYMENT_BANK_REPOSITORY');

export interface PaymentBankRepository {
  findDefault(): Promise<PaymentBank>
}
