import React, { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { css } from 'aphrodite';

import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { RocketInput } from './RocketInput';
import { rocketOrange } from '../../../assets/ColorContants';
import { RocketProgressFooter } from './RocketProgressFooter';
import { requestRocketState } from '../../../shared/RocketStartContants';
import { goNext, save, useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { stepViewAnimationStyle } from './RequestInput';
import { animationStyle } from '../../../shared/SharedAni';
import { useInjection } from '../../../../shared/presentation/InversifyProvider';
import { SCRIPT_LOGGER, ScriptLogger } from '../../../../shared/domain/ScriptLogger';
import { isEmptyCheck } from '../../../../shared/domain/CommonPolicy';
import { useAtom } from 'jotai';

export const Step02Mobile = ({onScrollUp}: { onScrollUp: () => void }) => {
  const {isMobile, isTablet} = useRecoilValue(totalScreenState);
  const [request, setRequest] = useAtom(requestRocketState);
  const styles = Step02MobileStyles({isMobile, isTablet});
  const history = useMyHistory();
  const scriptLogger = useInjection<ScriptLogger>(SCRIPT_LOGGER);
  const footerRef = useRef<any>(null);

  useEffect(() => {
    scriptLogger?.sendPathApproachedLog('/request/m/1');
  }, [])

  useEffect(() => {
    if (!isEmptyCheck(request.name)) {
      save(history, request);
    }
  }, [request]);

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      footerRef.current?.clickNext();
    }
  };
  const handleBlur = () => footerRef.current?.hideMessage();

  const onClickNext = () => goNext(history, request);
  const isDisabled = request.name === '';
  return (
    <div
      className={css(
        stepViewAnimationStyle,
        animationStyle(0, 0).popUp
      )}>
      <div className={css(styles.description)}>
        어떤분이신지 궁금해요 <span role={'img'} aria-label={'search'}>🙌</span>
      </div>
      <RocketInput
        id={'request_name'}
        addStyle={styles.space}
        placeholder={'이름'}
        onChange={value => setRequest({...request, name: value})}
        value={request.name}
        onFocus={onScrollUp}
        onBlur={handleBlur}
        onKeydown={handleKeyPress}
      />
      <div className={css(styles.small)}>
        * 상담 신청을 위해 남겨주신 개인정보는<br/>
        &nbsp;&nbsp; 개인정보취급방침에 의해 100% 안전하게 보호됩니다.
      </div>
      <RocketProgressFooter
        ref={footerRef}
        progress={'1'}
        onClickNext={onClickNext}
        isDisabled={isDisabled}
      />
    </div>
  );
};

const Step02MobileStyles = ({isMobile, isTablet}: { isMobile: boolean, isTablet: boolean }) => CreateStyle({
  description: {
    fontSize: '16px/@18px',
    lineHeight: '30px/@31px',
    marginBottom: '36px/@30px',
    color: '#242424',
  },
  orange: {
    color: rocketOrange,
    fontWeight: 'bold',
  },
  space: {
    marginBottom: '15px/@196px'
  },
  small: {
    alignSelf_: 'flex-start/',
    marginBottom: '42px/',
    fontSize: '14px/',
    lineHeight: '22px/',
    letterSpacing: '-0.02em/',
    color: '#A0A4A7',
  },
}, isMobile, isTablet);
