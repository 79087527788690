import { AirtableHelper } from '../airtable/AirtableScrapHelper';

export const createRecommendCode = async () => {
  let code = '';
  for (let i = 0; i < 30; i++) {
    code = generateRandomCode(6);
    if (!await AirtableHelper.isDuplicatedCode(code)) {
      break;
    }
  }
  return code;
};

export const createTaxagentCode = async (preCode: string) => {
  let code = '';
  const regex = /\d{4}/g;
  for (let i = 0; i < 30; i++) {
    code = preCode + generateRandomCode(4);
    if (regex.test(code)) {
      continue;
    }
    if (!await AirtableHelper.isDuplicatedCode(code)) {
      break;
    }
  }
  return code;
};


const generateRandomCode = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
