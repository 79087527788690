import Cookies from 'universal-cookie';

import { inject, injectable } from 'inversify';
import { FRANCHISE_REQUEST_REPOSITORY, FranchiseRequestRepository } from '../domain/FranchiseRequestRepository';
import { isEmptyCheck } from '../domain/CommonPolicy';
import { plainToClass } from 'class-transformer';
import { AddRequestDto, AddRequestUpdateDto } from '../infra/AddRequestDto';
import { KAKAO_MESSAGE_SENDER, KakaoMessageSender } from '../domain/KakaoMessageSender';
import { IRequestForm, RequestError, REQUEST_SERVICE, RequestService } from './RequestStore';
import { SCRIPT_LOGGER, ScriptLogger } from '../domain/ScriptLogger';
import { PHONE_FORMAT_POLICY, PhoneFormatPolicy } from '../domain/PhoneFormatPolicy';
import { ITotalKeyword } from '../presentation/KeywordAtom';

export const FRANCHISE_REQUEST_SERVICE = Symbol('FRANCHISE_REQUEST_SERVICE');

@injectable()
export class FranchiseRequestService {
  @inject(PHONE_FORMAT_POLICY)
  private readonly phoneFormatPolicy!: PhoneFormatPolicy;

  @inject(FRANCHISE_REQUEST_REPOSITORY)
  private readonly franchiseRequestRepository!: FranchiseRequestRepository;

  @inject(KAKAO_MESSAGE_SENDER)
  private readonly kakaoMessageSender!: KakaoMessageSender;

  @inject(SCRIPT_LOGGER)
  private readonly scriptLogger!: ScriptLogger;

  @inject(REQUEST_SERVICE)
  private readonly requestService!: RequestService;

  checkRequest(request: IRequestForm): RequestError {
    return RequestError.create({
      isNameError: request.name === '',
      isPhoneEmpty: request.phone === '',
      isPhoneError: !this.phoneFormatPolicy.isValid(request.phone),
      isQuestionError: request.question === '',
      isBizTypeError: request.bizType === '' ?? false,
    });
  }

  async createRequest(
    {request, keyword}: { request: IRequestForm; keyword: ITotalKeyword }
  ): Promise<string> {
    const form = plainToClass(AddRequestDto, {
      name: request.name,
      phone: request.phone,
      question: request.question,
      firstKeyword: keyword.first,
      finalKeyword: keyword.final,
      bizType: request.bizType ?? '',
    });

    const recordId = await this.franchiseRequestRepository.addRequest(form);
    const cookie = new Cookies();
    cookie.set('record_id', recordId, {path: '/', maxAge: 60 * 30});
    return recordId;
  }

  async sendRequestWithRecordId(request: IRequestForm, keyword: ITotalKeyword, cookie: Cookies): Promise<string> {
    const recordId = await this.requestService.getRecordId();
    const isExist = await this.isExistRecord(recordId);
    if (isEmptyCheck(recordId) || !isExist) {
      return this.createRequest({request, keyword});
    }
    const updateForm = plainToClass(AddRequestUpdateDto, {
      id: recordId,
      name: request.name,
      phone: request.phone,
      question: request.question,
      keyword,
      bizType: request.bizType ?? '',
    });
    await this.franchiseRequestRepository.updateRequest(updateForm);

    cookie.set('record_id', recordId, {path: '/', maxAge: 60 * 30});
    return recordId;
  }

  async sendFinalRequestWithRecordId(request: IRequestForm, keyword: ITotalKeyword, cookie: Cookies) {
    const result = await this.sendRequestWithRecordId(request, keyword, cookie);
    if (!result) {
      return new Error('정보 업데이트가 되지 않았습니다.');
    }

    this.scriptLogger.sendRequestOccuredLog();
    try {
      await this.kakaoMessageSender.sendConfirmRequestMessage(request.name, request.phone);
      cookie.remove('record_id', {path: '/'});
      cookie.remove('request_start', {path: '/'});
    } catch (e) {
      console.log(e);
    }
  }

  async isExistRecord(recordId: string) {
    let result = '';
    try {
      result = await this.franchiseRequestRepository.findExistByRecordId(recordId);
    } catch (e) {
      return false;
    }
    return !isEmptyCheck(result);
  }
}
