import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { closedPage } from '../HistoryHelper';

export function BackgroundButton() {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <Link
      className="popup background touchable"
      to={closedPage}
      data-testid='background'
      id={'test_pop'}
    />
  );
}

export function CustomBackgroundButton(props: { onClick: () => void}) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div
      className="popup background touchable"
      data-testid='background'
      onClick={props.onClick}
      id={'test_pop'}
    />
  );
}
