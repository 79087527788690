import { css } from 'aphrodite';
import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { CreateSingle, CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { rocketOrange, rocketWhite } from '../../../assets/ColorContants';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { requestPage, useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { ReactComponent as Arrow } from '../../../assets/svgs/button-arrow.svg';
import { spoqa } from '../../../../shared/css/css-utils';

interface IFooterProps {
  progress: string,
  onClickNext?: () => void,
  onClickPrev?: () => void,
  toolTipMsg?: string,
  isDisabled?: boolean,
}

export const RocketProgressFooter = React.forwardRef((
  {
    progress,
    onClickNext,
    onClickPrev,
    toolTipMsg,
    isDisabled = false,
  }: IFooterProps, ref) => {
  const {isMobile, isDesktop, isTablet} = useRecoilValue(totalScreenState);
  const history = useMyHistory();
  const [isHover, setIsHover] = useState(false);
  const [percent, setPercent] = useState(mapPreviousProgressToPercent(progress, isDesktop));

  useEffect(() => {
    setTimeout(() => setPercent(mapProgressToPercent(progress, isDesktop)), 500);
  }, []);
  const orangeBar = CreateSingle({
    position: 'absolute',
    top: 0,
    left: 0,
    width: `${percent}%`,
    height: '4px/4px@5px',
    background: rocketOrange,
    borderRadius: '30px/22px@34px',
    transition: '1s ease',
  }, isMobile, isTablet);

  useImperativeHandle(ref, () => ({
    clickNext: () => handleNextClick(),
    hideMessage: () => handleHideMessage(),
  }));

  const handleNextClick = useCallback(() => {
    if (isDisabled) {
      setIsHover(true);
      return;
    }

    if (onClickNext) {
      setTimeout(onClickNext, 100);
      return;
    }
    history.push(requestPage(Number(progress) + 1));
  }, [isDisabled, onClickNext]);

  const handleHideMessage = useCallback(() => {
    if (isDisabled) {
      setIsHover(false);
    }
  }, [isDisabled]);

  const styles = Styles({isMobile, isDisabled, isTablet});

  const tooltip_width = CreateSingle({
    width: mapToolTipMessageToWidth(mapToolTipMessageToPercent(progress, isDesktop, toolTipMsg))
  }, isMobile, isTablet);

  return (
    <>
      <div className={css(styles.page_navigation)}>
        {progress}/{isDesktop ? 3 : 4}
      </div>
      <div className={css(styles.progress_bar)}>
        <div className={css(orangeBar)}/>
      </div>
      <div className={css(styles.buttons)}>
        <div
          className={css(styles.button, styles.previous)}
          onClick={onClickPrev ? onClickPrev : () => history.goBack()}
        >
          <Arrow className={css(styles.arrow)}/>
          <div className={css(styles.previous_title)}>이전</div>
        </div>
        <div className={css(styles.button_div)}>
          {
            (isDisabled && isHover) && (
              <div className={css(styles.tooltip_div)}>
                <div className={css(
                  styles.tooltip_box,
                  tooltip_width,
                )}>
                  {mapToolTipMessageToPercent(progress, isDesktop, toolTipMsg)}
                </div>
                <div className={css(styles.tooltip_triangle)}/>
              </div>
            )
          }
          <div
            id={'next_button'}
            className={css(styles.button, styles.next)}
            onClick={handleNextClick}
            onMouseOver={() => isDisabled && setIsHover(true)}
            onMouseOut={() => isDisabled && setIsHover(false)}
          >
            {
              (isDesktop && progress === '3') || (!isDesktop && progress === '4') ? '신청' : '다음'
            }
          </div>
        </div>
      </div>
    </>
  );
});

function mapPreviousProgressToPercent(progress: string, isDesktop: boolean): number {
  switch (progress) {
    case '2':
      return isDesktop ? 33 : 25;
    case '3':
      return isDesktop ? 66 : 50;
    case '4':
      return isDesktop ? 100 : 75;
    default:
      return 0;
  }
}

function mapProgressToPercent(progress: string, isDesktop: boolean): number {
  switch (progress) {
    case '0':
      return 0;
    case '1':
      return isDesktop ? 33 : 25;
    case '2':
      return isDesktop ? 66 : 50;
    case '3':
      return isDesktop ? 100 : 75;
    case '4':
      return 100;
    default:
      return 0;
  }
}

export function mapToolTipMessageToPercent(progress: string, isDesktop: boolean, toolTipMsg?: string): string {
  switch (progress) {
    case '1':
      return isDesktop ? toolTipMsg ?? '' : '이름을 입력해주세요.';
    case '2':
      return isDesktop ? '업종을 입력해주세요.' : '연락처를 입력해주세요.';
    case '3':
      return isDesktop ? '' : '업종을 입력해주세요.';
    default:
      return '';
  }
}

function mapToolTipMessageToWidth(toolTipMsg?: string): string {
  return toolTipMsg === '연락처를 입력해주세요.' ? '166px/162px@170px' : '153px/150px@156px';
}

const Styles = (
  {
    isMobile,
    isDisabled,
    isTablet
  }: { isMobile: boolean, isDisabled: boolean, isTablet: boolean }
) => CreateStyle({
  page_navigation: {
    textAlign: 'right',

    width: '100%',
    height: '/20px',
    color: rocketOrange,

    fontSize: '18px/16px',
    lineHeight: '23px/20px',
    marginBottom: '16px/11px',
    letterSpacing: '2px',
  },
  progress_bar: {
    position_: 'relative',
    width: '344px/365px@380px',
    height: '4px/4px@5px',
    background: '#F2F2F2',
    borderRadius: '30px/22px@34px',
    boxShadow: 'inset 1.5px 1.5px 1.5px rgba(0, 0, 0, 0.03)/inset 1.5px 1.5px 1.5px rgba(0, 0, 0, 0.03)@inset 2.32778px 2.32778px 2.32778px rgba(0, 0, 0, 0.03)',
    marginBottom: '31px/27px@39px',
  },
  buttons: {
    width: '344px/365px@380px',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    zIndex: 1,

    position: 'relative',
  },
  button: {
    width: '86px/94px@100px',
    height: '50px/48px@54px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px/10px',

    fontWeight: 'bold',
    fontSize: '18px/16px@19px',
    lineHeight: '23px/20px@24px'
  },
  previous: {
    color: '#868686',

    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',

    fontWeight_: 'normal/bold',
    fontFamily: spoqa,

    ':hover': {
      cursor: 'pointer',
      color: rocketOrange,
      ':nth-child(1) > svg': {
        stroke: rocketOrange,
      }
    },

    '-webkit-tap-highlight-color': 'transparent',
  },
  previous_title: {
    paddingTop: '2px/2px',
    paddingLeft: '/@7px',
  },
  next: {
    backgroundColor: rocketOrange,
    opacity: isDisabled ? 0.3 : undefined,
    color: 'white',

    ':hover': {
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      backgroundColor: isDisabled ? undefined : '#FF8F4F',
    },

    ':active': {
      transform: 'scale(0.98)',
      opacity: isDisabled ? 0.3 : 0.9,
      transition: 'all .02s',
    }
  },
  arrow: {
    width: '28px/25px',
    height: '28px/25px',
    padding: '0px 0px 0px 9px/0px 0px 0px 9px',

    stroke: '#868686',
    transform: 'rotate(180deg)',
  },
  button_div: {
    position: 'relative',
    display: 'flex',
    justifyContent_: 'flex-end/center',
  },
  tooltip_div: {
    position: 'absolute',
    transform: 'translateY(-100%)',
  },
  tooltip_box: {
    backgroundColor: '#474242',
    height: '46px/40px',
    borderRadius: '10px',

    position: 'relative',
    top: -14,
    zIndex: 1,

    fontSize: '15px/14px',
    lineHeight: '19px/18px',
    color: rocketWhite,
    fontFamily: spoqa,
    whiteSpace: 'nowrap',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    padding: '10px 12px/11px 15px@11px 15px',
    letterSpacing: '-0.02em',
  },
  tooltip_triangle: {
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderTop: '8px solid #474242',

    position: 'absolute',
    left: '70%/50%',
    transform: 'translateX(-50%)',

    bottom: 8,
  },

}, isMobile, isTablet);
