import React, { useEffect } from 'react';
import { css } from 'aphrodite';

import KakaoPopUpRocketStyle from './KakaoPopUpRocketStyle';
import { useRecoilState } from 'recoil';
import { KAKAO_SERVICE, kakaoErrorState, kakaoPhoneState, KakaoService } from '../../../shared/store/KakaoStore';
import { useInjection } from '../../../shared/presentation/InversifyProvider';
import { PHONE_FORMAT_POLICY, PhoneFormatPolicy } from '../../../shared/domain/PhoneFormatPolicy';
import { SCRIPT_LOGGER, ScriptLogger } from '../../../shared/domain/ScriptLogger';
import { useHistory } from 'react-router-dom';
import { IHistory, isPartnershipPage, kakaoPage } from '../../../shared/presentation/HistoryHelper';

export function KakaoInputRocket() {
  const [error, setError] = useRecoilState(kakaoErrorState);
  const [phone, setPhone] = useRecoilState(kakaoPhoneState);
  const phoneFormatPolicy = useInjection<PhoneFormatPolicy>(PHONE_FORMAT_POLICY);
  const styles = KakaoPopUpRocketStyle(false);
  const scriptLogger = useInjection<ScriptLogger>(SCRIPT_LOGGER);
  const kakaoService = useInjection<KakaoService>(KAKAO_SERVICE);
  const history = useHistory<IHistory>();

  useEffect(() => {
    if (!isPartnershipPage(history)) {
      scriptLogger.sendPathApproachedLog('/kakao');
    }
  }, [])

  const onClickNext = (): void => {
    const isPhoneError = kakaoService.checkPhone(phone);
    if (isPhoneError) {
      setError(true);
      return;
    }
    history.push(kakaoPage(1));
  }

  return (
    <React.Fragment>
      <div className={css(styles.inputBox)}>
        <input
          className={css(styles.input, error && styles.input_error)}
          type='text'
          placeholder="휴대폰 번호를 입력하여 주세요"
          onChange={e => setPhone(phoneFormatPolicy.changeFormat(e.target.value))}
          value={phone}
          data-testid='phone'
          id={'number_input'}
        />
        {error && (
          <div className={css(styles.error)}>
            휴대폰번호를 확인해주세요
          </div>
        )}
      </div>
      <div className={css(styles.kakao_comment, styles.kakao_margin_bottom)}>
        카카오톡 메시지가 전달될 예정이오니, 휴대폰을 확인해 주세요.
      </div>
      <button
        className={css(styles.button)}
        type='button'
        onClick={onClickNext}
        id={'kakao_confirm_button'}
      >
        확인
      </button>
    </React.Fragment>
  );
}
