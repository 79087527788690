import { isMobile } from 'react-device-detect';

export function getMobileType(): string {
  // const filter = 'win16|win32|win64|mac|macintel';
  // if (navigator.platform) {
  //   if (filter.indexOf(navigator.platform.toLowerCase()) < 0) {
  //     return 'M';
  //   }
  //   return 'P';
  // }
  // return 'P';
  return isMobile ? 'M' : 'P';
}
