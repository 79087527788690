import { sharedShape } from './SharedStyleSheet';
import { StyleDeclarationValue } from 'aphrodite';

export const buttonCss = {
  padding: '15px 32px',
  display: 'inline-block' as 'inline-block',
  textAlign: 'center' as 'center',
  textDecoration: 'none' as 'none',
}

export const center = {
  display: 'flex' as 'flex',
  alignItems: 'center' as 'center',
  justifyContent: 'center' as 'center',
}

export const defaultSlider = {
  autoplay: true,
  dots: false,
  arrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 7000,
  speed: 100,
  pauseOnHover: false,
}

export function triggerCss(trigger: boolean, style: StyleDeclarationValue, other?: StyleDeclarationValue): StyleDeclarationValue {
  return trigger ? style : other ?? sharedShape.default;
}

