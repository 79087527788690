import React, { useImperativeHandle, useState } from 'react';
import { Link } from 'react-router-dom';
import { css } from 'aphrodite';
import Cookies from 'universal-cookie';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { rocketOrange } from '../../../assets/ColorContants';
import { spoqa } from '../../../../shared/css/css-utils';
import { RocketButton } from '../../../shared/RocketButton';
import { requestRocketAtom, requestRocketState } from '../../../shared/RocketStartContants';
import { useInjection } from '../../../../shared/presentation/InversifyProvider';
import { isLoadingState } from '../../../../shared/presentation/LoadingFrame';
import { showUp } from '../../../shared/SharedAni';
import { useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { CustomRequestService } from '../../../../shared/service/RequestService';
import { totalKeywordAtom } from '../../../../shared/presentation/KeywordAtom';

export const PopUpForAgreement = React.forwardRef(({cookie}: { cookie: Cookies }, ref) => {
  const {isMobile, isTablet} = useRecoilValue(totalScreenState);
  const [isShow, setIsShow] = useState(false);
  const request = useAtomValue(requestRocketState);
  const resetRequest = useResetAtom(requestRocketState);
  // const requestService = useInjection<RequestService>(REQUEST_SERVICE);
  const requestService = useInjection(CustomRequestService);
  const keyword = useAtomValue(totalKeywordAtom);
  const setIsLoading = useSetRecoilState(isLoadingState);
  const history = useMyHistory();

  useImperativeHandle(ref, () => ({
    show: () => setIsShow(true),
    hide: () => setIsShow(false),
  }));

  const onClickSendRequest = async () => {
    setIsLoading(true);
    let error = false;
    try {
      await requestService?.sendRequest(request, keyword);
    } catch (e) {
      error = true;
      alert('업데이트에 실패하였습니다. 다시 시도해주시기 바랍니다');
    } finally {
      setIsLoading(false);
      if (!error) {
        resetRequest();
        cookie.remove('record_id');
        history.push('/request_success');
      }
    }
  };

  const styles = Styles(isMobile, isTablet);
  return (
    <>
      {isShow && (
        <div className={css(styles.background)}>
          <div className={css(
            styles.wrapper,
            styles.ani,
          )}>
            <div className={css(styles.title)}>
              <div>
                <span style={{color: rocketOrange}}>(필수)</span> 개인정보취급방침 동의
              </div>
              <Link
                to={'/terms_of_service'}
                target={'_blank'}
                className={css(styles.show)}
              >
                보기
              </Link>
            </div>
            <RocketButton
              width={'360px/310px@360px'}
              height={'64px/52px@64px'}
              paddingBottom={'/2px'}
              borderRadius={'10px/8px@12px'}
              onClick={onClickSendRequest}
              id={'request_agreement'}
            >
              동의하고 신청완료
            </RocketButton>
          </div>
          <div
            className="background touchable"
            onClick={() => setIsShow(false)}
          />
        </div>
      )}
    </>
  );
});

const Styles = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    width: '100vw',
    height: '100%',

    position: 'fixed',
    left: 0,
    top: 0,

    zIndex: 100,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',

    display: 'flex',
    alignItems_: 'flex-end/flex-start@flex-start',
    justifyContent: 'center',

    paddingTop: '/339px@311px',
  },
  wrapper: {
    width: '414px/369px@406px',
    height: '200px/166px@183px',

    background: 'white',
    boxShadow: '/3px 10px 24px rgba(0, 0, 0, 0.03)@3px 11px 28px rgba(0, 0, 0, 0.03)',
    borderRadius: '19px 19px 0 0/15px@17px',

    padding: '42px 27px 0px/31px 33px 0px@34px 23px 0px',

    fontFamily: spoqa,
    fontSize: '18px/15px',
    lineHeight: '32px/29px@38px',

    transition: 'all .3s',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '31px/27px@22px',
    marginLeft: '6px/@12px',
    marginRight: '10px/@20px',
  },
  show: {
    fontFamily: spoqa,
    fontSize: '/15px',
    lineHeight: '/34px',

    color: '#9D9D9D',
    cursor: 'pointer',
    marginRight: '/7px',

    transform: '/translateY(-9%)',
  },
  ani: {
    animationName: [showUp],
    animationDuration: '.5s',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 1,
  }
}, isMobile, isTablet);
