export const IAM_PORT_MEMBERSHIP_ID_CODE = 'imp44924246'; // imp_152507663256
export const IAM_PORT_REST_API_KEY = '7377025518839161';
export const IAM_PORT_REST_API_KEY_SECRET = 'fc4f979807f043389d0e325d48758e4caa861ea0946d917bc763b197a56e6b3bc947b9176ce6f5ba';


export const IAM_PORT_KG = 'html5_inicis';
export const IAM_PORT_NICE = 'nice';
export const IAM_PORT_TOSS_PAY = 'tosspay';
export const IAM_PORT_KAKAO_PAY = 'kakaopay';

