import { PaymentBank } from '../domain/PaymentBankRepository';

export class AirtablePaymentBank implements PaymentBank {
  private readonly id!: string;
  private readonly Name!: string;
  private readonly 은행명!: string;
  private readonly 계좌번호!: string;
  private readonly 예금주!: string;

  get accountNo(): string {
    return this.계좌번호;
  }

  get accountOwner(): string {
    return this.예금주;
  }

  get bankName(): string {
    return this.은행명;
  }
}
