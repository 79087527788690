import { StyleSheet } from 'aphrodite';

const floatingKeyframes = {
  '0%': {
    transform: 'translateY(0)',
  },
  '50%': {
    transform: 'translateY(10%)',
  },
  '100%': {
    transform: 'translateY(0)',
  },
};

const floatingLocationKeyframes = {
  '0%': {
    transform: 'translateY(0)',
  },
  '50%': {
    transform: 'translateY(3px)',
  },
  '100%': {
    transform: 'translateY(0)',
  },
};

export const slideIn = {
  '0%': {
    transform: 'translateX(0)',
    opacity: 0.5,
  },
  '50%': {
    transform: 'translateX(20%)',
    opacity: 1,
  },
  '100%': {
    transform: 'translateX(0)',
    opacity: 0.5,
  },
};

export const slideBottom = {
  '0%': {
    transform: 'translateY(0)',
    opacity: 0.5,
  },
  '50%': {
    transform: 'translateY(20%)',
    opacity: 1,
  },
  '100%': {
    transform: 'translateY(0)',
    opacity: 0.5,
  },
};

export const fadeIn = {
  '0%': {
    transform: 'translateY(-100%)',
    opacity: 0.5,
    pointerEvents: 'none',
  },
  '50%': {
    pointerEvents: 'none',
  },
  '100%': {
    transform: 'translateY(0%)',
    opacity: 1,
  },
};

export const fadeOut = {
  '0%': {
    transform: 'translateY(0%)',
    opacity: 1,
  },
  '50%': {
    pointerEvents: 'none',
  },
  '100%': {
    transform: 'translateY(-100%)',
    opacity: 0,
    pointerEvents: 'none',
  },
};

export const popUp = {
  '0%': {
    transform: 'scale(0.98)',
    opacity: 0,
  },
  '100%': {
    transform: 'scale(1)',
    opacity: 1,
  }
};

export const showUp = {
  '0%': {
    transform: 'translateY(100%)',
    opacity: 0,
  },
  '100%': {
    transform: 'translateY(0%)',
    opacity: 1,
  }
};

export const animationStyle = (delay: number = 0, delayTime: number = 0.3) => StyleSheet.create({
  none: {
    transition: `transform 0.75s ${delay}s, opacity 0.75s ${delay}s`,
  },
  floating: {
    animationName: [floatingKeyframes],
    animationDuration: '1s',
    animationTimingFunction: 'ease-in-out',
    animationDelay: `${delayTime}s`,
    animationIterationCount: 'infinite',
    transition: `transform 0.75s ${delay}s, opacity 0.75s ${delay}s`,
  },
  floatingLocation: {
    animationName: [floatingLocationKeyframes],
    animationDuration: '1s',
    animationTimingFunction: 'ease-in-out',
    animationDelay: `${delayTime}s`,
    animationIterationCount: 'infinite',
    transition: `transform 0.75s ${delay}s, opacity 0.75s ${delay}s`,
  },
  slideIn: {
    animationName: [slideIn],
    animationDuration: '1s',
    animationTimingFunction: 'ease-in-out',
    animationDelay: `${delayTime}s`,
    animationIterationCount: 'infinite',
    transition: `transform 0.75s ${delay}s, opacity 0.75s ${delay}s`,
  },
  menuOpen: {
    animationName: [fadeIn],
    animationDuration: '.5s',
    animationTimingFunction: 'ease',
    animationDelay: `${delayTime}s`,
    animationIterationCount: 1,
    transition: `transform 0.1s ${delay}s, opacity 0.1s ${delay}s`,
  },
  menuClose: {
    animationName: [fadeOut],
    animationDuration: '.5s',
    animationTimingFunction: 'ease',
    animationDelay: `${delayTime}s`,
    animationIterationCount: 1,
    transition: `transform 0.1s ${delay}s, opacity 0.1s ${delay}s`,
  },
  popUp: {
    animationName: [popUp],
    animationDuration: '.5s',
    animationTimingFunction: 'ease-in-out',
    animationDelay: `${delayTime}s`,
    animationIterationCount: 1,
    transition: `transform 0.1s ${delay}s, opacity 0.1s ${delay}s`,
  },
  slideBottom: {
    animationName: [slideBottom],
    animationDuration: '1s',
    animationTimingFunction: 'ease-in-out',
    animationDelay: `${delayTime}s`,
    animationIterationCount: 'infinite',
    transition: `transform 0.75s ${delay}s, opacity 0.75s ${delay}s`,
  }
});

