import { atom } from 'recoil';
import { inject, injectable } from 'inversify';
import 'reflect-metadata';

import { KAKAO_MESSAGE_SENDER, KakaoMessageSender } from '../domain/KakaoMessageSender';
import { PHONE_FORMAT_POLICY, PhoneFormatPolicy } from '../domain/PhoneFormatPolicy';

export interface KakaoPageManager {
  isShowKakaoPopup(): boolean;
  showKakaoPopup(): void;
  closePopups(): void;
  isInputProcess(): boolean;
  isCheckProcess(): boolean;
  isSuccessProcess(): boolean;
  showKakaoPopup(): void;
  showKakaoCheckPopup(): void;
  showKakaoSuccessPopup(): void;
}

export const kakaoErrorState = atom<boolean>({
  key: 'kakaoErrorState',
  default: false,
});

export const kakaoPhoneState = atom<string>({
  key: 'kakaoState',
  default: '',
})

export const KAKAO_SERVICE = Symbol('KAKAO_SERVICE');

@injectable()
export class KakaoService {
  @inject(PHONE_FORMAT_POLICY)
  phoneFormatPolicy!: PhoneFormatPolicy;

  @inject(KAKAO_MESSAGE_SENDER)
  kakaoMessageSender!: KakaoMessageSender;

  public checkPhone(phone: string): boolean {
    return phone === '' || !this.phoneFormatPolicy.isValid(phone);
  }

  public async sendRequest(phone: string) {
    await this.kakaoMessageSender.sendConsultantMessage(phone);
  }
}
