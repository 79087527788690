import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { CreateSingle, CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';

interface Props {
  addStyle: Object,
  placeholder: string,
  height?: string
  children?: any
  onFocus?: () => void,
  onBlur?: () => void,
  onChange?: (value: any) => void,
  value?: string,
  id?: string,
  onKeydown?: (e: any) => void,
}

export function RocketTextarea(
  {
    addStyle,
    placeholder,
    children,
    onFocus,
    onBlur,
    height,
    onChange,
    onKeydown,
    value,
    id
  }: Props) {
  const {isMobile, isTablet} = useRecoilValue(totalScreenState);
  const styles = inputStyle(isMobile, isTablet, height ?? '');

  return (
    <div
      className={css(CreateSingle({position: 'relative'}, isMobile, isTablet), addStyle)}
    >
      <textarea
        className={css(styles.txtArea)}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={e => onChange?.(e.target.value)}
        onKeyDown={onKeydown}
        value={value}
        id={id}
      />
      {children}
    </div>
  );
}

const inputStyle = (isMobile: boolean, isTablet: boolean, height: string) => CreateStyle({
  txtArea: {
    width: '360px/370px@378px',
    height: height ?? '206px/50px@200px',
    padding: '23px 26px/17px 28px@18px 26px',

    background: '#F6F6F6',
    borderRadius: '15px',

    fontFamily: 'Spoqa',
    fontSize: '17px/18px@18px',
    lineHeight: '21px/28px@28px',
    letterSpacing: '-0.02em',
    transition: 'all 0.3s',

    resize: 'none',
    border: '1.5px solid transparent',
    '::placeholder': {
      color: '#A0A4A7',
    },
    ':hover': {
      backgroundColor: '#EBEBEB',
      cursor: 'pointer',
    },
    ':focus': {
      backgroundColor: 'white',
      border: '1.5px solid #FF9F68',
    },

    overflowY_: 'overlay',

    '::-webkit-scrollbar': {
      width: '/15px',
      borderRadius: '/15px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#DADADA',
      borderRadius: '/15px',
      'background-clip': 'padding-box',
      border: 'solid 3px transparent',
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      borderRadius: '/15px',
      boxShadow: 'inset 0px 0px 10px transparent',

      background: 'transparent',
      ':horizontal': {
        background: 'transparent',
      },
    },
  }
}, isMobile, isTablet);
