import { injectable } from "inversify";

@injectable()
export class WebSocketManager {
  private socket: any;

  connect(url: string): any {
    if (this.socket == null) {
      this.socket = window.io(url);
    }
    return this.socket;
  }

  getSocket(): any {
    return this.socket;
  }

}
