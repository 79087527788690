import { AddPartnershipRequestDto } from '../infra/AddPartnershipRequestDto';
import { AddRequestDto, AddRequestUpdateDto } from '../infra/AddRequestDto';

export const REQUEST_REPOSITORY = Symbol('REQUEST_REPOSITORY');

export interface RequestRepository {
  addRequest(request: AddRequestDto): Promise<string>;
  addPartnership(request: AddPartnershipRequestDto): Promise<string>;
  updateRequest(request: AddRequestUpdateDto): Promise<string>;
  findExistByRecordId(recordId: string): Promise<any>;
}
