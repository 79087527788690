import Airtable from 'airtable';

const AIRTABLE_API_KEY = process.env.REACT_APP_AIRTABLE_API_KEY!;
const AIRTABLE_BASE_ID = process.env.REACT_APP_AIRTABLE_BASE_ID!;
const AIRTABLE_TABLE = process.env.REACT_APP_AIRTABLE_TABLE_NAME!;

export class AirtableHelper {
  static mapToJson(entity: any) {
    const res = entity._rawJson;
    return {
      id: res.id,
      ...res.fields,
    };
  }

  static isDuplicatedCode = async (code: string) => {
    const base = new Airtable({ apiKey: AIRTABLE_API_KEY }).base(AIRTABLE_BASE_ID);
    const airtable = base(AIRTABLE_TABLE);

    const result = await airtable.select({
      filterByFormula: `{추천인코드} = '${code}' `,
      maxRecords: 1,
    }).firstPage();
    return result.length !== 0;
  };
}
