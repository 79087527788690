import Airtable from 'airtable';

import {
  AddTaxagentRequestDto,
  IAddInfo, IAddInfoFile, IAddInfoPersonal,
  IBasicInfo,
  IBasicInfoUpdate,
  IHometaxKakaoLoginInfo, IHometaxLoginInfo,
  IRequest, IUploadFileInfo,
  TaxagentRequestRepository
} from '../domain/TaxagentRequestRepository';

export class AirtableTaxagentRequestRepository implements TaxagentRequestRepository {
  private readonly base: any;
  private readonly table: any;

  constructor(
    baseId: string = '',
    apiKey: string = '',
    tableName: string = '',
    // 인식 타입, partner: PT, taxcenter: TM
    private readonly siteType: string = '',
  ) {
    if (baseId === '') {
      return;
    }
    this.base = new Airtable({apiKey}).base(baseId);
    this.table = this.base(tableName);
  }

  async addTaxagentRequest(request: AddTaxagentRequestDto): Promise<string> {
    if (this.table === undefined) {
      return Promise.reject('airtable setting not exist');
    }

    const body = {
      '유입경로': ['홈페이지'],
      'SITE': this.siteType,
      '신청자명': request.name,
      '연락처': request.phone,
      '종소세안내문': request.noticeFiles.map(file => ({url: file})) ?? [],
      '기타서류': request.otherFiles.map(file => ({url: file})) ?? [],
      '신고유형': request.declareType,
      '기타사항(고객)': request.etcInfo,
      '추천인코드': request.recommendCode,
      '고객코드': request.code,
      KEYWORD: request.keyword,
    };
    const result = await this.table.create(body);
    return result.id;
  }

  async addTaxagentRequestBasicInfo(request: IBasicInfo): Promise<string> {
    if (this.table === undefined) {
      return Promise.reject('airtable setting not exist');
    }

    const body = {
      '유입경로': ['홈페이지'],
      'SITE': this.siteType,
      '신청자명': request.name,
      '연락처': request.phone,
      '신고유형': request.declareType,
      '부양가족확인': request.isDependents,
      '추천인코드': request.recommendCode,
      '고객코드': request.code,
      '심야': request.isLateTimeContact,
      KEYWORD: request.keyword,
    };
    const result = await this.table.create(body);
    return result.id;
  }

  async findOneRequestWithBasicInfo(phone: string, name: string): Promise<IRequest | undefined> {
    if (phone === '' || name === '') {
      return undefined;
    }

    if (this.table === undefined) {
      return Promise.reject('airtable setting not exist');
    }

    const result = await this.table.select({
      filterByFormula: `AND({연락처} = '${phone}', {신청자명} = '${name}')`,
      maxRecords: 1,
    }).firstPage();

    if (result.length === 0) {
      return undefined;
    }

    return this.mapFieldToRequest(result[0]);
  }

  async findOneRequestWithRequestId(id: string): Promise<IRequest | undefined> {
    if (this.table === undefined) {
      return Promise.reject('airtable setting not exist');
    }

    const result = await this.table.find(id);

    if (result == null) {
      return undefined;
    }

    return this.mapFieldToRequest(result);
  }

  async updateTaxagentRequestBasicInfo(request: IBasicInfoUpdate): Promise<string> {
    if (this.table === undefined) {
      return Promise.reject('airtable setting not exist');
    }

    const result = await this.table.update([{
      id: request.id,
      fields: {
        '신고유형': request.declareType,
        '부양가족확인': request.isDependents,
        '추천인코드': request.recommendCode,
        '심야': request.isLateTimeContact,
      },
    }]);
    return result[0].id;
  }

  async updateTaxagentHomeTaxKakaoLoginInfo(request: IHometaxKakaoLoginInfo): Promise<string> {
    if (this.table === undefined) {
      return Promise.reject('airtable setting not exist');
    }

    const result = await this.table.update([{
      id: request.id,
      fields: {
        '주민등록번호': request.personalNumber,
      },
    }]);
    return result[0].id;
  }

  async updateTaxagentHomeTaxLoginInfo(request: IHometaxLoginInfo): Promise<string> {
    if (this.table === undefined) {
      return Promise.reject('airtable setting not exist');
    }

    const result = await this.table.update([{
      id: request.id,
      fields: {
        '홈택스ID': request.homeTaxId,
        '홈택스PW': request.homeTaxPassword,

      },
    }]);
    return result[0].id;
  }

  async updateTaxagentUploadFileInfo(request: IUploadFileInfo): Promise<string> {
    if (this.table === undefined) {
      return Promise.reject('airtable setting not exist');
    }

    const entity = await this.table.find(request.id);
    const before: string[] = entity.fields['종소세안내문'] ?? [];
    const after = [...before, ...request.files.map(file => ({url: file})) ?? []];

    const result = await this.table.update([{
      id: request.id,
      fields: {
        '종소세안내문': after,
      },
    }]);
    return result[0].id;
  }

  async updateTaxagentRequestAddInfo(request: IAddInfo): Promise<string> {
    const addTable = this.base('신고담당자');
    if (addTable === undefined) {
      return Promise.reject('airtable setting not exist');
    }

    const existingEntity = await addTable.select({
      filterByFormula: `AND({연락처} = '${request.phone}', {신청자명} = '${request.name}')`,
      maxRecords: 1,
    }).firstPage();

    const isExist = existingEntity != null && existingEntity.length !== 0;
    const before: string[] = isExist ? existingEntity[0].fields['기타서류'] ?? [] : [];
    const after = [...before, ...request.otherFiles.map(file => ({url: file})) ?? []];

    const body = {
      '고객코드': request.code,
      '신청자명': request.name,
      '연락처': request.phone,
      '은행명': request.bankName,
      '계좌번호': request.accountNumber,
      '입금자명': request.depositorName,
      '이메일': request.email,
      '인적공제정보': request.personalExemptionInfo,
      '기타서류': after,
      '참고사항(고객)': request.note,
    };

    if (isExist) {
      await addTable.update([{
        id: existingEntity[0].id,
        fields: body,
      }]);
      return existingEntity[0].id;
    }
    const result = await addTable.create(body);
    return result.id;
  }

  async updateTaxagentRequestAddInfoPersonal(request: IAddInfoPersonal): Promise<string> {
    const addTable = this.base('신고담당자');
    if (addTable === undefined) {
      return Promise.reject('airtable setting not exist');
    }
    const existingEntity = await addTable.select({
      filterByFormula: `AND({연락처} = '${request.phone}', {신청자명} = '${request.name}')`,
      maxRecords: 1,
    }).firstPage();

    const isExist = existingEntity != null && existingEntity.length !== 0;
    const tax_before: string[] = isExist ? existingEntity[0].fields['연말정산간소화자료'] ?? [] : [];
    const other_before: string[] = isExist ? existingEntity[0].fields['기타서류'] ?? [] : [];

    const tax_after = [...tax_before, ...request.taxAdjustmentFiles.map(file => ({url: file})) ?? []];
    const other_after = [...other_before, ...request.otherFiles.map(file => ({url: file})) ?? []];

    const body = {
      '고객코드': request.code,
      '신청자명': request.name,
      '연락처': request.phone,
      '은행명': request.bankName,
      '계좌번호': request.accountNumber,
      '입금자명': request.depositorName,
      '이메일': request.email,
      '인적공제정보': request.personalExemptionInfo,
      '인건비': request.laborCost,
      '임차료': request.rent,
      '연말정산간소화자료': tax_after,
      '기타서류': other_after,
      '참고사항(고객)': request.note,
    };

    if (isExist) {
      await addTable.update([{
        id: existingEntity[0].id,
        fields: body,
      }]);
      return existingEntity[0].id;
    }
    const result = await addTable.create(body);
    return result.id;
  }

  async updateTaxagentRequestAddInfoFile(request: IAddInfoFile): Promise<string> {
    if (this.table === undefined) {
      return Promise.reject('airtable setting not exist');
    }

    const entity = await this.table.find(request.id);
    const before: string[] = entity.fields['기타서류'] ?? [];
    const after = [...before, ...request.otherFiles.map(file => ({url: file})) ?? []];

    const result = await this.table.update([{
      id: request.id,
      fields: {
        '기타서류': after,
      },
    }])
    return result[0].id;
  }

  async mapFieldToRequest(entity: any): Promise<IRequest> {
    const res = entity._rawJson;
    return ({
      id: res.id,
      code: res.fields['고객코드'],
      phone: res.fields['연락처'],
      name: res.fields['신청자명'],
    });
  }
}
