import { css } from 'aphrodite';
import React from 'react';

import { ReactComponent as MenuClose } from '../../assets/svgs/menu-close-mobile.svg';
import { useMyHistory } from '../../../shared/presentation/HistoryHelper';
import { HeaderStyle } from './Header';
import { HeaderButtonStyles } from '../../../shared/presentation/header/HeaderButtonStyle';
import { CreateSingle } from '../../../shared/presentation/shared/CreateStyle';
import { useRecoilValue } from 'recoil';
import { totalScreenState } from '../../../shared/presentation/MobileFrame';

export function MobileRequestHeader() {
  const history = useMyHistory();
  const {isMobile, isTablet} = useRecoilValue(totalScreenState);
  const custom = HeaderStyle(isMobile, isTablet);

  const styles = HeaderButtonStyles({
    isMobile: isMobile,
    isTop: false,
    isTablet: isTablet,
  });

  const backgroundStyle = CreateSingle({
    justifyContent: 'flex-end',
    backgroundColor: 'transparent',
    borderBottom: undefined,
    top: 0,
    opacity: 1,
  }, isMobile, isTablet);

  return (
    <div className={css(custom.background, backgroundStyle)} id={'header'}>
      <div
        className={css(styles.close_div)}
        id={'request_close_button'}
        onClick={() => {
          document.body.blur();
          setTimeout(() => history.push('/'), 500);
        }}
      >
        <MenuClose
          className={css(styles.close_button)}
        />
      </div>
    </div>
  );
}
