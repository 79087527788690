import { CreateStyle } from '../shared/CreateStyle';
import { spoqa } from '../../css/css-utils';

interface Props {
  isMobile: boolean,
  isTop: boolean,
  baseColor?: string,
  isTablet?: boolean,
  tabletHeight?: number,
}

export const HeaderButtonStyles = (
  {
    isMobile,
    isTop,
    baseColor = '#1b315d',
    isTablet = false,
    tabletHeight = 77,
  }: Props) => CreateStyle({
  header: {
    zIndex: 50,
    position: 'fixed',
    width: '100%',
    height: 'calc(env(safe-area-inset-top) + 50.5px)/96px',
    top: 0,

    paddingTop: 'env(safe-area-inset-top)/',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: isTop ? 'rgba(255, 255, 255, 0)' : 'rgba(255, 255, 255, 1)',
    borderBottom: `solid 1px ${isTop ? 'rgba(255, 255, 255, 0.25)' : '#d8d8d8'}`,
  },
  headerIsPopUp: {
    top: '85px/75px',
  },
  transitionStyle: {
    transition: '.3s',
  },
  buttons: {
    display: 'inline-flex',
    height: '100%',
    transition: 'all 0.3s',
  },
  button: {
    width: `56px/@${tabletHeight}px@40px`,
    height: '100%',
    border: 'none',
    borderLeft: `solid 1px ${isTop ? 'rgba(255, 255, 255, 0.25)' : '#d8d8d8'}`,

    paddingLeft: '/42px@',
    paddingRight: '/42px@',
    textDecoration: 'none',

    display: 'flex',
    alignItems: 'center',
    justifyContent_: 'center/flex-start@center',

    backgroundColor: 'inherit',
    fontFamily: spoqa,
    fontWeight: 500,
    fontSize: '13px/18px',
    color: isTop ? 'white' : baseColor,
    fill: isTop ? 'white' : baseColor,

    cursor: 'pointer',
    whiteSpace: 'pre',
  },
  no_border: {
    borderLeft: 'none',
  },
  text: {
    whiteSpace: 'pre',
  },
  buttonIcon: {
    width: `23px/25px@${(tabletHeight !== 77) ? (tabletHeight * 0.4) : 26}px`,
    height: `23px/25px@${(tabletHeight !== 77) ? (tabletHeight * 0.4) : 26}px`,
    fill: 'inherit',
  },
  closeIcon: {
    marginRight: '15px/',
    marginTop: '5px/',
    cursor: 'pointer',
  },
  close_div: {
    width: '44px/@50px',
    height: '44px/@50px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    ':active': {
      '-webkit-tap-highlight-color': 'transparent',
      backgroundColor: '#F5F5F5',
      borderRadius: '5px/',
    },
  },
  close_button: {
    width: '16px/@20px',
    height: '16px/@20px',

    cursor: 'pointer',
  },
}, isMobile, isTablet, {mobileWidth: 414, width: 1920});
