import { ReactComponent as Arrow } from './svgs/arrow.svg';
import { ReactComponent as Award } from './svgs/award.svg';
import { ReactComponent as Blog } from './svgs/blog.svg';
import { ReactComponent as Call } from './svgs/call.svg';
import { ReactComponent as Close } from './svgs/close.svg';
import { ReactComponent as Facebook } from './svgs/facebook.svg';
import { ReactComponent as Kakao } from './svgs/kakao.svg';
import { ReactComponent as Menu } from './svgs/menu.svg';
import { ReactComponent as Tick } from './svgs/tick.svg';
import { ReactComponent as DropDown } from './svgs/drop-down.svg';
import { ReactComponent as CloseSlim } from './svgs/close-slim.svg';

export const SharedAssets = {
  Arrow,
  Award,
  Blog,
  Call,
  Close,
  CloseSlim,
  Facebook,
  Kakao,
  Menu,
  Tick,
  DropDown,
};
