import { PrivacyPolicyNotCheckedError } from '../store/PrivacyPolicyNotCheckedError';
import { InvalidRequestError } from '../store/InvalidRequestError';
import { PartnerForm, SharedPartnerField, SharedPartnerForm } from '../airtable/PartnerForm';
import { classToClass } from 'class-transformer';

export class FranchisePartnerForm extends SharedPartnerForm implements PartnerForm {
  isPrivacyPolicyChecked = true;

  checkIsValid() {
    if (!this.isPrivacyPolicyChecked) {
      throw new PrivacyPolicyNotCheckedError();
    }
    if (this.companyName === '' || this.userName === '' || this.phone === '') {
      throw new InvalidRequestError();
    }
  }

  copy(): FranchisePartnerForm {
    return classToClass(this);
  }

  setIsPrivacyPolicyChecked(value: boolean): this {
    this.isPrivacyPolicyChecked = value;
    return this;
  }

  get fields(): SharedPartnerField {
    return this.getFields();
  }
}
