import { css } from 'aphrodite';
import React, { RefObject } from 'react';
import { CreateSingle } from '../../../../shared/presentation/shared/CreateStyle';
import { useRecoilValue } from 'recoil';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';

interface Props {
  addStyle: Object,
  placeholder: string,
  children?: any
  onFocus?: () => void,
  onBlur?: () => void,
  onChange?: (value: any) => void,
  onKeydown?: (e: any) => void,
  value?: string,
  refObject?: RefObject<HTMLInputElement>,
  id?: string,
  isNumeric?: boolean,
}

export function RocketInput(
  {
    addStyle,
    placeholder,
    children,
    onFocus,
    onBlur,
    onChange,
    value,
    onKeydown,
    refObject,
    id,
    isNumeric
  }: Props) {
  const {isMobile, isTablet} = useRecoilValue(totalScreenState);
  const inputStyle = CreateSingle({
    width: '360px/370px@380px',
    height: '60px/50px@54px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 27px/0px 28px@0px 28px',

    background: '#F6F6F6',
    borderRadius: '108px/100px@126px',

    fontFamily: 'Spoqa',
    fontSize: '17px/16px@18px',
    lineHeight: '21px/20px@23px',
    letterSpacing: '-0.02em',
    transition: 'all 0.3s',
    '::placeholder': {
      color: '#A0A4A7',
    },
    ':hover': {
      backgroundColor: '#EBEBEB',
      cursor: 'pointer',
    },
    ':focus': {
      backgroundColor: 'white',
      border: '1.6px solid #FF9F68',
    },
  }, isMobile, isTablet);

  return (
    <div
      className={css(CreateSingle({position: 'relative'}, isMobile, isTablet), addStyle)}
    >
      <input
        className={css(inputStyle)}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={e => onChange?.(e.target.value)}
        value={value}
        onKeyDown={e => onKeydown?.(e)}
        ref={refObject}
        onClick={e => e.stopPropagation()}
        id={id}
        inputMode={isNumeric ? 'numeric' : 'text'}
      />
      {children}
    </div>
  );
}
