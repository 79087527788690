import Airtable from 'airtable';

import { RequestRepository } from '../domain/RequestRepository';
import { getMobileType } from '../helper/DeviceHelper';
import { PartnerForm } from '../airtable/PartnerForm';
import { AirtableRequestForm, mapRequestToEntity } from '../airtable/RequestForm';
import { AirtableRequestUpdateForm } from './AddRequestDto';

export interface Fields {
  CREATE_DATE: string,
  NAME: string,
  TEL: string,
  COMMENT: string,
  KEYWORD: string,
}

export interface RocketStart extends Fields {
  SITE: string,
  TYPE: string,
}

export class AirtableRequestRepository implements RequestRepository {
  private readonly base: any;
  private readonly table: any;

  constructor(
    baseId: string = '',
    apiKey: string = '',
    tableName: string = '',
    // 인식 타입, partner: PT, taxcenter: TM
    private readonly siteType: string = '',
  ) {
    if (baseId === '') {
      return;
    }
    this.base = new Airtable({apiKey}).base(baseId);
    this.table = this.base(tableName);
  }

  async addRequest(
    request: AirtableRequestForm,
    addOptions = {
      SITE: this.siteType,
      TYPE: getMobileType(),
    }
  ): Promise<string> {
    if (this.table === undefined) {
      return Promise.reject('airtable setting not exist');
    }
    const result = await this.table.create({
      ...request.fields,
      ...addOptions,
    });
    return result.id;
  }

  async addPartnership(
    request: PartnerForm,
    addOptions = {
      SITE: this.siteType,
      TYPE: getMobileType(),
    }
  ): Promise<string> {
    if (this.table === undefined) {
      return Promise.reject('airtable setting not exist');
    }

    const result = await this.table.create({
      ...request.fields,
      ...addOptions,
    });
    return result.id;
  }

  async updateRequest(
    request: AirtableRequestUpdateForm
  ): Promise<string> {
    if (this.table === undefined) {
      return Promise.reject('airtable setting not exist');
    }

    const data = mapRequestToEntity(request);
    return await this.table.update([data]);
  }

  async findExistByRecordId(recordId: string): Promise<any> {
    if (this.table === undefined) {
      return Promise.reject('airtable setting not exist');
    }
    const findExists = await this.table.find(recordId);
    if (findExists == null) {
      throw new Error('Record is not exist');
    }
    return findExists.id;
  }
}
