import { History } from 'history';
import * as _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { scrollWindow } from '../helper/Util';
import queryString from 'query-string';

export interface IHistory {
  isPopUpMenu?: boolean,
  isPopUpKakao?: boolean,
  isPopUpVideo?: boolean,
  isPopUpRequest?: boolean,
  isPopUpNews?: boolean,
  isPopUpAds?: boolean,
  isBack?: boolean,
  process?: number,
  requestType?: string,
  name?: string,
  phone?: string,
  question?: string,
  bizType?: string,
  isPrivacyPolicyChecked?: boolean,
  [key: string]: any,
}

export const useMyHistory = () => useHistory<IHistory>();
export const useMyQuery = () => {
  const location = useLocation();
  return queryString.parse(location.search);
}

export function goNext(history: History<IHistory>, input?: Object) {
  const progress = history.location.state?.process != null ? history.location.state.process + 1 : 1;
  history.push({
    pathname: process.env.REACT_APP_USE_REQUEST_PAGE === 'TRUE' ? `/request/${progress}` : undefined,
    state: {
      ...history.location.state,
      ...input,
      isPopUpRequest: true,
      process: progress,
      isBack: false,
    }
  });
}

export function save(history: History<IHistory>, input?: Object) {
  const progress = history.location.state?.process != null ? history.location.state.process : 1;
  history.replace({
    pathname: process.env.REACT_APP_USE_REQUEST_PAGE === 'TRUE' ? `/request/${progress}` : undefined,
    state: {
      ...history.location.state,
      ...input,
      isPopUpRequest: true,
      process: progress,
      isBack: false,
    }
  });
}

export function goPage(history: History<IHistory>, input?: Object, index?: number) {
  history.push({
    pathname: process.env.REACT_APP_USE_REQUEST_PAGE === 'TRUE' ? `/request/${index}` : undefined,
    state: {
      ...history.location.state,
      ...input,
      isPopUpRequest: true,
      process: index,
      isBack: false,
    }
  });
}

export function goBuildsPre(history: History<IHistory>, input?: Object) {
  const progress = history.location.state?.process != null ? (history.location.state.process - 1) : 0;
  history.push({
    pathname: process.env.REACT_APP_USE_REQUEST_PAGE === 'TRUE' ? `/request/${progress}` : undefined,
    state: {
      ...history.location.state,
      ...input,
      isPopUpRequest: true,
      process: progress,
      isBack: true,
    }
  });
}

export const requestPage = (progress: number = 0) => ({
  pathname: process.env.REACT_APP_USE_REQUEST_PAGE === 'TRUE' ? `/request/${progress}` : undefined,
  state: {
    isPopUpRequest: true,
    process: progress,
  }
});

export const kakaoPage = (process: number = 0) => ({
  state: {
    isPopUpKakao: true,
    process,
  }
});

export const menuPage = {
  state: {
    isPopUpMenu: true,
  }
};

export const adsPage = {
  state: {
    isPopUpAds: true,
  }
};

export const newsPage = {
  state: {
    isPopUpNews: true,
  }
};

export const videoPage = {
  state: {
    isPopUpVideo: true,
  }
};

export const defaultPage = {
  pathname: '/',
  state: {},
};

export const closedPage = {
  state: {},
};

export const isShowRequestPopup = (history: History<IHistory>) => history.location.state?.isPopUpRequest ?? false;

export const isShowMenu = (history: History<IHistory>) => history.location.state?.isPopUpMenu ?? false;

export const isShowKakaoPopup = (history: History<IHistory>) => history.location.state?.isPopUpKakao ?? false;

export const isShowVideoPopup = (history: History<IHistory>) => history.location.state?.isPopUpVideo ?? false;

export const isShowNewsPopup = (history: History<IHistory>) => history.location.state?.isPopUpNews ?? false;

export const isShowAdsPopup = (history: History<IHistory>) => history.location.state?.isPopUpAds ?? false;

export const isAdsEnable = (history: History<IHistory>) => history.location.state == null || _.isEmpty(history.location.state) || history.location.state?.isPopUpAds;

export const isInputProcess = (history: History<IHistory>) => history.location.state?.process === 0 || history.location.state?.process == null;

export const isCheckProcess = (history: History<IHistory>) => history.location.state?.process === 1;

export const isSuccessProcess = (history: History<IHistory>) => history.location.state?.process === 2;

export const isPartnershipPage = (history: History<IHistory>) => history.location.pathname === '/partnership';

export const isMain = (history: History<IHistory>) => history.location.pathname === '/';

export const isPrivacyPolicy = (history: History<IHistory>) => history.location.pathname === '/privacy_policy';

export const isTermsOfService = (history: History<IHistory>) => history.location.pathname === '/terms_of_service';

export const isRegisterPage = (history: History<IHistory>) => history.location.pathname.includes('/register');

export const isPaymentPage = (history: History<IHistory>) => history.location.pathname.includes('/payment');

export const isKakaoInappBlockPage = (history: History<IHistory>) => history.location.pathname.includes('/ios');

export const isTheTaxReturn = (history: History<IHistory>) => history.location.pathname.includes('/tax-return');

export const isManualPage = (history: History<IHistory>) => history.location.pathname === '/manual';

export const movePath = (history: History<IHistory>, path: string) => {
  if (history.location.pathname === path) {
    history.push(closedPage);
    setTimeout(() => scrollWindow('main'), 500);
    return;
  }

  window.scrollTo(0, 0);
  history.push(path);
};

export const openPartnership = (history: History<IHistory>) => {
  if (isPartnershipPage(history)) {
    history.push(closedPage);
    return;
  }

  window.open('/partnership');
};

export const getFromHistory = (history: History<IHistory>, key: string) => {
  return history.location.state[key];
}
