import React from 'react';
import * as _ from 'lodash';

import { isWebpSupported } from 'react-image-webp/dist/utils';

export interface ImageProps {
  src: any,
  srcSet?: string,
  webp?: any,
  alt: string,
  className?: string,
  style?: any,
  onClick?: () => void,
  width?: string,
  height?: string,
  id?: string,
  ref?: any,
}

export const adjustImage = (img: any): any => {
  if (process.env.MOBILE != null && _.isString(img) && img.startsWith('/')) {
    return img.replace('/', '');
  }

  if (_.isString(img)) {
    return img;
  }
  return img?.default;
};

export function Image({ src, ref, alt, ...props }: ImageProps) {
  return (isWebpSupported() && props.webp !== undefined)
    ? (
      <div {...props}>
        <img
          alt={alt}
          style={{
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
          }}
          src={adjustImage(props.webp)}
        />
      </div>
    ) : (
      <img
        alt={alt}
        src={adjustImage(src)}
        ref={ref}
        {...props}
      />);
}
