import '../shared/helper/IEHelper';
import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import ReactDOM from 'react-dom';

import '../index.css';
import '../shared/helper/IEHelper';
import './assets/rocketstart.css';
import Loading from '../shared/presentation/Loading';
import KakaoPopUpRocket from './shared/kakao-popup/KakaoPopUpRocket';
import { RootFrame } from '../shared/presentation/RootFrame';
import { RequestInput } from './pages/popup/request-input/RequestInput';
import { RequestSuccessPage } from './pages/popup/request-input/RequestSuccessPage';
import * as serviceWorker from '../serviceWorker';

const Menu = lazy(() => import('./pages/popup/menu/Menu'));
const Home = lazy(() => import('./pages/home/Home'));
const PrivacyPolicyRocket = lazy(() => import('./shared/PrivacyPolicyRocket'));
const TermsOfServiceRocket = lazy(() => import('./shared/TermsOfServiceRocket'));

ReactDOM.render(
  <RootFrame project={'rocketstart'}>
    <Suspense fallback={<Loading/>}>
      {/*<Route component={RequestPopUp}/>*/}
      <Route component={KakaoPopUpRocket}/>
      <Route component={Menu}/>
      <Switch>
        <Route exact path='/privacy_policy' component={PrivacyPolicyRocket}/>
        <Route exact path='/terms_of_service' component={TermsOfServiceRocket}/>
        <Route exact path='/request/:progress' component={RequestInput}/>
        <Route exact path='/request_success' component={RequestSuccessPage}/>
        <Route component={Home}/>
      </Switch>
    </Suspense>
  </RootFrame>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
