import { css } from 'aphrodite';
import React, { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import Cookies from 'universal-cookie';

import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { RocketProgressFooter } from './RocketProgressFooter';
import { RocketTextarea } from './RocketTextarea';
import { PopUpForAgreement } from './PopUpForAgreement';
import { stepViewAnimationStyle } from './RequestInput';
import { animationStyle } from '../../../shared/SharedAni';
import { rocketContentBlack } from '../../../assets/ColorContants';
import { useInjection } from '../../../../shared/presentation/InversifyProvider';
import { SCRIPT_LOGGER, ScriptLogger } from '../../../../shared/domain/ScriptLogger';
import { requestRocketState } from '../../../shared/RocketStartContants';
import { useAtom } from 'jotai';

export const Step04 = ({onScrollUp, cookie}: { onScrollUp: () => void, cookie: Cookies }) => {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const popupRef = useRef<any>(null);
  const [{ question }, setRequest] = useAtom(requestRocketState);
  const styles = Styles({isMobile, isTablet});
  const scriptLogger = useInjection<ScriptLogger>(SCRIPT_LOGGER);
  const footerRef = useRef<any>(null);

  useEffect(() => {
    scriptLogger?.sendPathApproachedLog('/request/3');
  }, []);

  const onClickNext = async () => popupRef.current?.show();

  const placeholder = `법인설립과 관련하여 궁금한 점을 ${!isTablet ? '\n' : ''}남겨주세요.${isTablet ? '\n' : ''}(선택)`;
  return (
    <div
      className={css(
        stepViewAnimationStyle,
        animationStyle(0, 0).popUp
      )}>
      <PopUpForAgreement ref={popupRef} cookie={cookie}/>
      <div className={css(styles.description)}>
        마지막이에요 <span role={'img'} aria-label={'search'}>👍</span>
      </div>
      <RocketTextarea
        id={'request_comment'}
        addStyle={styles.space}
        placeholder={placeholder}
        height={'206px/186px@200px'}
        onChange={value => setRequest({ question: value })}
        value={question}
        onFocus={onScrollUp}
      />
      <RocketProgressFooter
        ref={footerRef}
        progress={isDesktop ? '3' : '4'}
        onClickNext={onClickNext}
        isDisabled={false}
      />
    </div>
  );
};

const Styles = ({isMobile, isTablet}: { isMobile: boolean, isTablet: boolean }) => CreateStyle({
  description: {
    fontSize: '16px/18px@18px',
    lineHeight: '30px/30px@31px',
    marginBottom: '39px/45px@30px',
    color: isMobile ? '#242424' : rocketContentBlack,
  },
  space: {
    marginBottom: '39px/32px@32px',
  },
}, isMobile, isTablet);
