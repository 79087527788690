import { ConversionLogger } from '../domain/ConversionLogger';

export class SmartLogger implements ConversionLogger {
  logConversionScript() {
    console.log('smartLog conversion');
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `var hpt_trace_info={'_mode':'q','_memid':''};`;
    document.head.appendChild(script);
  }
}
