import { currentDateString } from '../helper/Util';
import { AirtableRequestUpdateForm } from '../infra/AddRequestDto';

export interface SharedField {
  CREATE_DATE: string;
  NAME: string,
  TEL: string;
  COMMENT: string;
  KEYWORD: string;
  KEYWORD_2: string;
}

export interface AirtableRequestForm {
  fields: SharedField;
}

export class SharedRequestForm {
  public name = '';
  public phone = '';
  public question = '';
  public firstKeyword = '';
  public finalKeyword = '';
  public bizType = '';

  protected getFields(): SharedField {
    return {
      CREATE_DATE: currentDateString(),
      NAME: this.name,
      TEL: this.phone,
      COMMENT: this.bizType.length > 0 ? `${this.bizType}${this.question}` : this.question,
      KEYWORD: this.firstKeyword,
      KEYWORD_2: this.finalKeyword,
    };
  }

  setProps(props: Partial<SharedRequestForm>): this {
    this.name = props.name ?? this.name;
    this.phone = props.phone ?? this.phone;
    this.question = props.question ?? this.question;
    this.bizType = props.bizType ?? this.bizType;
    this.firstKeyword = props.firstKeyword ?? this.firstKeyword;
    this.finalKeyword = props.finalKeyword ?? this.finalKeyword;
    return this;
  }
}

export function mapRequestToEntity(request: AirtableRequestUpdateForm): any {
  const {ID, NAME, TEL, COMMENT} = request.fields;
  return {
    id: ID,
    fields: {
      NAME, TEL, COMMENT
    }
  };
}
