import Airtable from 'airtable';
import * as _ from 'lodash';
import { plainToClass } from 'class-transformer';

import {
  AddBuildRequestDto,
  BuildRepository,
  IBuildRequest,
  BuildExpert,
  BuildInfo
} from '../domain/BuildRepository';
import { currentDateString } from '../helper/Util';

export class AirtableBuildRepository implements BuildRepository {
  private readonly base: any;
  private readonly table: any;

  constructor(
    baseId: string = '',
    apiKey: string = '',
    tableName: string = '',
    private readonly siteType: string = '',
  ) {
    if (baseId === '') {
      return;
    }
    this.base = new Airtable({apiKey}).base(baseId);
    this.table = this.base(tableName);
  }

  async addRequest(request: AddBuildRequestDto): Promise<string> {
    if (this.table === undefined) {
      return Promise.reject('airtable setting not exist');
    }
    const body = {
      CREATE_DATE: currentDateString(),
      '★이름': request.name,
      '★휴대폰': request.phone,
      '★업종': request.companyType,
      '★E-MAIL': request.email,
      '★법인유형': request.corporationType,
      '★지역': request.place,
      '★비상주사무실': request.office,
      '★법인명(한글)': request.corporateName,
      '★법인명(영문)': request.corporateNameInEnglish,
      '★자본금(예상매출)': request.capitalStock,
      '★사업자 무료대행': request.freeAgency,
      '★유지계약서': request.contractAgreement,
      '★수수료 및 공과금': request.priceAgreement,
      '★공과금': request.utilityCharge,
      SITE: this.siteType,
      KEYWORD: request.keyword,
    };
    const result = await this.table.create(body);
    return result.id;
  }

  async findDataByRecordId(recordId: string): Promise<IBuildRequest | undefined> {
    try {
      const data = await this.table.find(recordId);
      return mapEntityToBuildRequest(data.fields);
    } catch (e) {
      console.log(e.error);
      if (e.error === 'NOT_FOUND') {
        return undefined;
      }
    }
  }

  async findTaxAccountantDataByKey(key: string): Promise<BuildExpert | undefined> {
    const [data] = await this.base('세무사').select({filterByFormula: `{◆key} = '${key}'`}).firstPage();
    if (data == null) {
      return undefined;
    }
    return mapEntityToBuildTaxAccountant(data.fields);
  }

  async findLawyerDataByKey(key: string): Promise<BuildExpert> {
    const [data] = await this.base('변호사/법무사').select({filterByFormula: `{name} = '${key}'`}).firstPage();
    return mapEntityToBuildLawyer(data.fields);
  }

  async getInfo(type: string): Promise<string> {
    const [data] = await this.base('정보').select({filterByFormula: `{항목} = '${type}'`}).firstPage();
    const result = mapEntityToBuildAccount(data.fields);
    return result.content;
  }
}

function mapEntityToBuildRequest(entity: any): IBuildRequest {
  return {
    name: entity['★이름'],
    phone: entity['★휴대폰'],
    capitalStock: entity['★자본금(예상매출)'],
    corporateName: entity['★법인명(한글)'],
    corporateNameInEnglish: entity['★법인명(영문)'],
    corporationType: entity['★법인유형'],
    companyType: entity['★업종'],
    place: entity['★지역'],
    office: entity['★비상주사무실'],
    freeAgency: entity['★사업자 무료대행'],
    email: entity['★E-MAIL'],
    contractAgreement: entity['★유지계약서'],
    priceAgreement: entity['★수수료 및 공과금'],
    status: entity['진행상황'],
    proceedMethod: entity['사업자 진행방법'] ?? '',
    utilityCharge: entity['★공과금'],
    taxAccountant: entity['(세)배정'],
    lawyer: entity['★(변)배정'],
    profileCompanyType: entity['★업종(프로필용)'] ?? '',
  };
}

function mapEntityToBuildTaxAccountant(entity: any): BuildExpert {
  const url = _.castArray(entity['◆사진'])[0]?.url;
  return plainToClass(BuildExpert, {
    name: entity['★이름'],
    companyName: entity['★상호'],
    ageGroup: entity['◆연령'],
    phone: entity['★연락처'] ?? '',
    address: entity['◆세무사무소 주소'],
    career: entity['◆경력'],
    introduce: entity['◆소개'],
    photo: url,
    majorCompanyType: entity['◆주요업종'] ?? [],
  });
}

function mapEntityToBuildLawyer(entity: any): BuildExpert {
  const url = _.castArray(entity['사진'])[0]?.url;
  return plainToClass(BuildExpert, {
    name: entity['name'],
    companyName: entity['company'],
    phone: entity['phone'] ?? '',
    address: entity['법무사사무소 주소'],
    career: entity['경력'],
    introduce: entity['소개'],
    photo: url,
  });
}

function mapEntityToBuildAccount(entity: any): BuildInfo {
  return {
    content: entity['내용'],
  };
}

