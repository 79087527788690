import { format } from 'date-fns';
import * as _ from 'lodash';
import { selector } from 'recoil';
import { History } from 'history';
import { mobileModel } from 'react-device-detect';
import {
  isMobileOnly,
  isTablet,
} from 'react-device-detect';

import { getOffset } from './SectionHelper';
import { defaultPage, IHistory, isMain, kakaoPage, requestPage } from '../presentation/HistoryHelper';
import { getDeviceValue } from '../presentation/shared/CreateStyle';

export const MOBILE_SIZE = 700;
export const TABLET_SIZE = 990;

export const isWorkTimeState = selector<boolean>({
  key: 'isWorkTimeState',
  get: () => {
    const date = new Date();
    return date.getHours() < 19 && date.getDay() !== 0
      && date.getDay() !== 7
      && date.getDay() !== 6;
  }
});

export const currentDateString = (): string => {
  const d = new Date();
  const year = d.getFullYear();
  const month =
    d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
  const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
  return `${year}-${month}-${day}`;
};

export function mapDeviceToKorean(value: string): string {
  switch (value) {
    case 'mobile':
      return '모바일';
    case 'tablet':
      return '테블릿';
    case 'desktop':
      return '데스크탑';
    default:
      return '데스크탑';
  }
}

export const getDeviceType = (): string => {
  if (isMobileOnly) {
    return 'mobile';
  }

  if (isTablet || isGalaxyFold()) {
    return getTabletSetting();
  }

  if (isMobileWidth()) {
    return 'mobile';
  }

  if (isTabletWidth()) {
    return 'tablet';
  }

  if (getBrowser() === 'Samsung') {
    return getTabletSetting();
  }

  return 'desktop';
};

const isMobileWidth = () => window.innerWidth <= MOBILE_SIZE;
const isTabletWidth = () => window.innerWidth <= TABLET_SIZE;
const getTabletSetting = () => isHorizontalMode() ? 'desktop' : 'tablet';

// export const isMobileSize = (): boolean => {
//   if (isGalaxyFold()) {
//     return false;
//   }
//
//   if (isMobileOnly) {
//     return true;
//   }
//   return window.innerWidth <= MOBILE_SIZE;
// };
//
// export const isTabletSize = (): boolean => {
//   if (isGalaxyFold()) {
//     return !isHorizontalMode();
//   }
//
//   if (getBrowser() === 'Samsung') {
//
//   }
//
//   if (isTablet) {
//     return window.innerWidth <= window.innerHeight * 1.2;
//   }
//   return (window.innerWidth <= TABLET_SIZE && window.innerWidth >= MOBILE_SIZE);
// };


const isGalaxyFold = (): boolean => mobileModel.substring(0, 5) === 'SM-F9';
const isHorizontalMode = (): boolean => window.innerWidth > window.innerHeight * 1.2;

export function isProductionMode() {
  return process.env.NODE_ENV === 'production';
}

export function scrollWindow(path: string) {
  const offset = getOffset(path);
  if (isIE()) {
    window.scrollTo(0, offset);
    return;
  }
  console.log(path + ': ' + offset);
  window.scrollTo({
    behavior: 'smooth',
    top: offset,
  });
}

export function isIE() {
  const ua = navigator.userAgent;
  return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1 || isEdgeLegacy();
}

export function isEdgeLegacy() {
  const ua = navigator.userAgent;
  const edgeVersion = ua.substr(ua.indexOf('Edge/') + 5, 2);
  if (parseInt(edgeVersion) <= 18) {
    return true;
  }
  return false;
}

export const createCalc = (value: number, window: number) => (value / window * 100).toFixed(1) + 'vw';

export const createPixel = (value: number, window: number, current: number) => (value / window * current).toFixed(0) + 'px';

export const createPixel2 = (
  value: string,
  width: number,
  option?: {
    width?: number,
    mobileWidth?: number,
    tabletWidth?: number,
    isMobile?: boolean,
    isTablet?: boolean,
  }): string => {
  return calculatePixel(value, width, option).toFixed(0) + 'px';
};

export const calculatePixel = (
  value: string,
  width: number,
  option?: {
    width?: number,
    mobileWidth?: number,
    tabletWidth?: number,
    isMobile?: boolean,
    isTablet?: boolean,
  }): number => {
  const result = getDeviceValue(value, option);
  const ratio = Number(result?.replace('vw', ''));
  return ratio * width / 100;
};

export function getFormalPhone(phone: string): string {
  return `82${phone.replace(/-/gi, '').substring(1)}`;
}

export function getFormalTime(): string {
  return format(new Date(), 'yyyy-MM-dd a hh:mm');
}

// 배열의 값을 index를 기준으로 true로 바꾼다
export function createAnimationState(length: number, compare: number): boolean[] {
  const numbers = _.range(length); // [ 0, 1, 2, 3, .. length ]
  // num = 0 ~ length, index = 0
  // isAnimateState: [ true, false, false, ... false ]
  // num = 0 ~ length, index = 1
  // isAnimateState: [ true, true, false, ... false ]

  return _.map(numbers, (__, num) => num <= compare);
}

export function getBrowser(): string {
  if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
    return 'Opera';
  }

  if (navigator.userAgent.indexOf('NAVER') !== -1) {
    return 'Naver';
  }

  if (navigator.userAgent.indexOf('SamsungBrowser') !== -1) {
    return 'Samsung';
  }

  if (navigator.userAgent.indexOf('Chrome') !== -1) {
    return 'Chrome';
  }

  if (navigator.userAgent.indexOf('Safari') !== -1) {
    return 'Safari';
  }

  if (navigator.userAgent.indexOf('Firefox') !== -1) {
    return 'Firefox';
  }

  if (isIE()) {
    return 'IE';
  }
  return 'unknown';
}

export function isImageFile(type: string): boolean {
  return (type === 'jpg' || type === 'png' || type === 'jpeg' || type === 'gif' || type === 'bmp');
}

export function openKakaoMobilePage() {
  if (getDeviceType() === 'tablet' && getBrowser() === 'Samsung') {
    window.open(process.env.REACT_APP_KAKAO_MOBILE_PAGE_URL);
    return;
  }
  window.open(process.env.REACT_APP_KAKAO_MOBILE_PAGE_URL + '/chat');
}

export function isChromeInIPhone(): boolean {
  // @ts-ignore
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  // @ts-ignore
  const isOpera = typeof window.opr !== 'undefined';
  const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
  const isIOSChrome = winNav.userAgent.match('CriOS');
  if (isIOSChrome) {
    return true;
  }
  if (
    isChromium !== null
    && typeof isChromium !== 'undefined'
    && vendorName === 'Google Inc.' && !isOpera && !isIEedge
  ) {
    return false;
  }
  return false;
}

export function checkSubLinkWithSection(sections: string[]) {
  const path = window.location.pathname.replace(/\//g, '');
  if (sections.indexOf(path) !== -1) {
    setTimeout(() => {
      let offset = getOffset(path);
      if (path === 'buildfirm') {
        offset = offset + 45;
      }
      if (path === 'support') {
        offset = offset + 70;
      }
      window.scrollTo(0, offset);
    }, 100);
    return;
  }
  window.scroll(0, 0);
}

export function moveToHome(history: History<IHistory>) {
  // document.body.style.overflow = 'auto';
  if (isMain(history)) {
    scrollWindow('main');
    history.push(defaultPage);
    return;
  }
  history.push(defaultPage);
  window.scrollTo(0, 0);
}

interface IHomeInit {
  history: History<IHistory>,
  request?: boolean,
  kakao?: boolean,
  sections: string[]
}

export function homeInit({history, request, kakao, sections}: IHomeInit) {
  if (request) {
    history.push(requestPage(0));
  }

  if (kakao) {
    history.push(kakaoPage(0));
  }

  checkSubLinkWithSection(sections);
}

export async function sleep(millis: number) {
  return new Promise(resolve => setTimeout(resolve, millis));
}
