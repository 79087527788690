import { inject, injectable } from 'inversify';
import axios from 'axios';

import { IRequestForm } from '../store/RequestStore';
import { KAKAO_MESSAGE_SENDER, KakaoMessageSender } from '../domain/KakaoMessageSender';
import { ITotalKeyword } from '../presentation/KeywordAtom';

export interface ICustomRequest extends IRequestForm {
  requestId: string | undefined;
}

@injectable()
export class CustomRequestService {
  @inject(KAKAO_MESSAGE_SENDER)
  private readonly kakaoMessageSender!: KakaoMessageSender;

  async sendRequest(request: ICustomRequest, keyword: ITotalKeyword): Promise<string> {
    const { first: firstKeyword, final: finalKeyword } = keyword;
    const url = process.env.REACT_APP_REQUEST_API_URL ?? 'https://msa.ailabscorp.com/register/request/startbiz';
    const { data: recordId } = await axios.post(
      url,
      { ...request, firstKeyword, finalKeyword },
    );
    return recordId;
  }

  async sendFinalRequest(request: ICustomRequest, keyword: ITotalKeyword): Promise<string> {
    const { first: firstKeyword, final: finalKeyword } = keyword;
    const url = process.env.REACT_APP_REQUEST_API_URL ?? 'https://msa.ailabscorp.com/register/request/startbiz';
    const { data: recordId } = await axios.post(
      url,
      { ...request, firstKeyword, finalKeyword, isFinal: true },
    );
    await this.kakaoMessageSender?.sendConfirmRequestMessage(request.name, request.phone);
    return recordId;
  }
}
