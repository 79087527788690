export const KAKAO_MESSAGE_SENDER = Symbol('KAKAO_MESSAGE_SENDER');

export interface BuildConfirmProps {
  targetPhone: string,
  name: string,
  email: string,
  corporateName: string,
  companyType: string,
  corporationType: string,
  place: string,
  capitalStock: string,
}

export interface KakaoMessageSender {
  sendConsultantMessage(phone: string): void;
  sendConfirmRequestMessage(name: string, phone: string, code?: string): void;
  sendConfirmRequestMessageForPartnership(name: string, phone: string): void;
  sendConfirmRequestMessageForBuild(
    {
      targetPhone,
      name,
      email,
      corporateName,
      companyType,
      corporationType,
      place,
      capitalStock
    }: BuildConfirmProps): void;
}
