// @ts-nocheck
import { ConversionLogger } from '../domain/ConversionLogger';

export class DaumReportLogger implements ConversionLogger {
  constructor(private readonly id?: string) {
    window.DaumConversionDctSv = 'type=P,orderID=,amount=';
    window.DaumConversionAccountID = id;
  }

  logConversionScript(): void {
    if (
      typeof window.DaumConversionScriptLoaded == 'undefined' &&
      window.location.protocol !== 'file:'
    ) {
      window.DaumConversionScriptLoaded = true;
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `${
        window.location.protocol === 'https:' ? 'https:' : 'http:'
      }//t1.daumcdn.net/cssjs/common/cts/vr200/dcts.js`;
      document.head.appendChild(script);
    }
  }
}
