import React from 'react';
import { atom, useRecoilValue } from 'recoil';

import Loading from './Loading';

export const isLoadingState = atom<boolean>({
  key: 'isLoadingState',
  default: false,
});

export function LoadingFrame() {
  const isLoading = useRecoilValue(isLoadingState);
  return (
    <>
      {isLoading && <Loading />}
    </>
  )
}
