// @ts-nocheck
import { ConversionLogger } from '../domain/ConversionLogger';

export class GoogleAdsLogger implements ConversionLogger {
  constructor(
    private readonly id?: string,
    private readonly eventId?: string
  ) {}

  logConversionScript() {
    window.dataLayer.push('event', 'conversion', {
      send_to: this.id + '/' + this.eventId
    })
    window.gtag('event', 'conversion', {
      send_to: this.id + '/' + this.eventId
    });
    window.gtag_report_conversion();
  }
}
