import React, { useCallback, useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';
import Cookies from 'universal-cookie';
import { useAtom, useAtomValue } from 'jotai';

import { RocketInput } from './RocketInput';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { requestRocketState } from '../../../shared/RocketStartContants';
import { goNext, save, useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { RocketProgressFooter } from './RocketProgressFooter';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { useInjection } from '../../../../shared/presentation/InversifyProvider';
import { PHONE_FORMAT_POLICY, PhoneFormatPolicy } from '../../../../shared/domain/PhoneFormatPolicy';
import { stepViewAnimationStyle } from './RequestInput';
import { animationStyle } from '../../../shared/SharedAni';
import { SCRIPT_LOGGER, ScriptLogger } from '../../../../shared/domain/ScriptLogger';
import { isEmptyCheck } from '../../../../shared/domain/CommonPolicy';
import { CustomRequestService } from '../../../../shared/service/RequestService';
import { totalKeywordAtom } from '../../../../shared/presentation/KeywordAtom';

export const Step03Mobile = ({onScrollUp, cookie}: { onScrollUp: () => void, cookie: Cookies }) => {
  const {isMobile, isTablet} = useRecoilValue(totalScreenState);
  const [request, setRequest] = useAtom(requestRocketState);
  const styles = Step03MobileStyles({isMobile, isTablet});
  const history = useMyHistory();
  const phoneFormatPolicy = useInjection<PhoneFormatPolicy>(PHONE_FORMAT_POLICY);
  const scriptLogger = useInjection<ScriptLogger>(SCRIPT_LOGGER);
  const footerRef = useRef<any>(null);
  const isDisabled = request.phone === '' || (!phoneFormatPolicy.isValid(request.phone));
  // const requestService = useInjection<RequestService>(REQUEST_SERVICE);
  const requestService = useInjection(CustomRequestService);
  const keyword = useAtomValue(totalKeywordAtom);

  useEffect(() => {
    scriptLogger?.sendPathApproachedLog('/request/m/2');
  }, []);

  useEffect(() => {
    if (!isDisabled) {
      save(history, request);
    }
  }, [isDisabled, request]);

  const onClickNext = useCallback(async () => {
    await save(history, request);
    if (!isEmptyCheck(request.phone)) {
      requestService?.sendRequest(request, keyword);
    }
    await goNext(history, request);
  }, [request, history]);

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      footerRef.current?.clickNext();
    }
  };
  const handleBlur = () => footerRef.current?.hideMessage();
  return (
    <div
      className={css(
        stepViewAnimationStyle,
        animationStyle(0, 0).popUp
      )}>
      <div className={css(styles.description)}>
        어디로 연락드릴까요? <span role={'img'} aria-label={'search'}>📨</span>
      </div>
      <RocketInput
        id={'request_phone'}
        addStyle={styles.space_bottom}
        placeholder={'연락처'}
        onChange={value => setRequest({...request, phone: value})}
        value={request.phone}
        onFocus={onScrollUp}
        isNumeric={true}
        onKeydown={handleKeyPress}
        onBlur={handleBlur}
      />
      <RocketProgressFooter
        ref={footerRef}
        progress={'2'}
        onClickNext={onClickNext}
        isDisabled={isDisabled}
      />
    </div>
  );
};

const Step03MobileStyles = ({isMobile, isTablet}: { isMobile: boolean, isTablet: boolean }) => CreateStyle({
  description: {
    fontSize: '16px/@18px',
    lineHeight: '30px/@31px',
    marginBottom: '36px/@30px',
    color: '#242424',
  },
  space_bottom: {
    marginBottom: '101px/@196px'
  },
}, isMobile, isTablet);

