import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { closedPage, IHistory } from './HistoryHelper';

export const KeyboardFrame = () => {
  const history = useHistory<IHistory>();
  const escFunction = (e: any) => {
    if(e.key === "Escape"){
      history.push(closedPage);
    }
  }

  useEffect(() => {
    window?.addEventListener("keydown", escFunction);
    return () => window?.removeEventListener("keydown", escFunction);
  });

  return (<></>);
}
