import React, { useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { css } from 'aphrodite';

import { SharedAssets } from '../../../shared/assets/SharedAssets';
import KakaoPopUpRocketStyle from './KakaoPopUpRocketStyle';
import { BackgroundButton } from '../../../shared/presentation/kakao-popup/BackgroundButton';
import { ReactComponent as CloseIcon } from '../../../shared/assets/svgs/close-slim.svg';
import {
  closedPage,
  IHistory,
  isCheckProcess,
  isInputProcess,
  isShowKakaoPopup,
  isSuccessProcess
} from '../../../shared/presentation/HistoryHelper';
import { KakaoInputRocket } from './KakaoInputRocket';
import { KakaoCheckRocket } from './KakaoCheckRocket';
import { KakaoSuccessRocket } from './KakaoSuccessRocket';
import { useRecoilValue } from 'recoil';
import { totalScreenState } from '../../../shared/presentation/MobileFrame';
import { useInjection } from '../../../shared/presentation/InversifyProvider';
import { LOCATION_SERVICE, LocationService } from '../../../shared/store/LocationStore';

function KakaoPopUpRocket() {
  const history = useHistory<IHistory>();

  return (
    <>{isShowKakaoPopup(history) && <KakaoBody />}</>
  );
}

function KakaoBody() {
  const { isDesktop } = useRecoilValue(totalScreenState);
  const styles = KakaoPopUpRocketStyle(false);
  const history = useHistory<IHistory>();
  const locationService = useInjection<LocationService>(LOCATION_SERVICE);

  useEffect(() => {
    if (!isDesktop) {
      locationService.openKakaoMobilePage();
      history.goBack();
    }
  }, []);

  const closePopup = () => history.push(closedPage);
  return (
    <>
      {isDesktop && (
        <div className='popup background'>
          <div className={css(styles.popup)}>
            <CloseIcon
              className={css(styles.close_button)}
              onClick={closePopup}
              data-testid='close'
            />
            <div className={css(styles.firstLine)} onClick={closePopup}>
              <SharedAssets.Kakao className={css(styles.kakaoImage)}/>
              <p>카카오톡 상담</p>
            </div>
            {isInputProcess(history) && <KakaoInputRocket/>}
            {isCheckProcess(history) && <KakaoCheckRocket/>}
            {isSuccessProcess(history) && <KakaoSuccessRocket />}
          </div>
          <BackgroundButton/>
        </div>
      )}
    </>
  );
}


export default withRouter(KakaoPopUpRocket);
