import axios from 'axios';

import { TaxReturnForm } from '../store/TaxReturnForm';
export const KAKAO_LOGIN_URL = process.env.REACT_APP_API_BASE_URL + '/taxagent/kakao-login';
export const TAX_RETURN_KAKAO_LOGIN_URL = process.env.REACT_APP_API_BASE_URL + '/meta-tax/request-login/hometax';
export const TAX_RETURN_KAKAO_LOGIN_CONFIRM_URL = process.env.REACT_APP_API_BASE_URL + '/meta-tax/scrap/hometax';
export const TAX_RETURN_INSURANCE_KAKAO_LOGIN_URL = process.env.REACT_APP_API_BASE_URL + '/meta-tax/request-login/insurance';
export const TAX_RETURN_INSURANCE_KAKAO_REQUEST_URL = process.env.REACT_APP_API_BASE_URL + '/meta-tax/request-insurance';
export const TAX_RETURN_INSURANCE_KAKAO_LOGIN_CONFIRM_URL = process.env.REACT_APP_API_BASE_URL + '/meta-tax/scrap/insurance';
export const CRAWLING_URL = process.env.REACT_APP_API_BASE_URL + '/taxagent/crawl-incomes';

interface KakaoLogin {
  name: string,
  phone: string,
  ssn1: string,
  ssn2: string
}

interface TaxReturnKakaoLogin {
  name: string,
  phone: string,
  socialSecurityNo: string,
}

interface TaxReturnKakaoLoginConfirm {
  request: any
}

interface Crawling {
  authId: string,
  recordId: string,
}

export const getAuthIdFromKakaoLogin = async (info: KakaoLogin) => {
  const response = await axios.post(KAKAO_LOGIN_URL, {
    ...info,
  });
  return response.data;
};

export const getResultFromKakaoLoginTaxReturn = async (info: TaxReturnForm): Promise<{ spreadSheetId: string, request: any }> => {
  const { data } = await axios.post(TAX_RETURN_KAKAO_LOGIN_URL, info.toJson());
  return data;
};

export const getConfirmFromKakaoLoginTaxReturn = async (request: any) => {
  const { data } = await axios.post(TAX_RETURN_KAKAO_LOGIN_CONFIRM_URL, request);
  return data;
};

export const getResultFromKakaoLoginInsuranceTaxReturn = async (info: TaxReturnForm) => {
  const { data } = await axios.post(TAX_RETURN_INSURANCE_KAKAO_REQUEST_URL, info.toJson());
  return data;
};

export const getConfirmFromKakaoLoginInsuranceTaxReturn = async (request: any) => {
  const { data } = await axios.post(TAX_RETURN_INSURANCE_KAKAO_LOGIN_CONFIRM_URL,request);
  return data;
};

export const crawlingIncomes = async (info: Crawling) => {
  const response = await axios.post(CRAWLING_URL, {
    ...info,
  });
  return response.data;
};

export function getCashNumber(number: string): string {
  const regex = /(\d{1,10})(\d{3})/;
  return number.replace(regex, '$1,$2').replace(regex, '$1,$2');
}
