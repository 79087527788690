import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useAtom } from 'jotai';
import { atom } from 'recoil';
import * as _ from 'lodash';

import { KeywordMapper } from '../domain/KeywordMapper';
import { keywordSourceAtom, totalKeywordAtom } from './KeywordAtom';

export const keywordState = atom<string>({
  key: 'keywordState',
  default: '',
});

export const AdsKeywordStorePage = () => {
  const location = useLocation();

  const [keyword, setKeyword] = useAtom(totalKeywordAtom);
  const [, setKeywordSource] = useAtom(keywordSourceAtom);

  useEffect(() => {
    const query = queryString.parse(location.search);
    if (query.keyword !== '') {
      if (!_.isEmpty(query)) {
        setKeywordSource(query);
      }
      const adsKeyword = KeywordMapper.mapKeyword(query);
      if (keyword.first === '') {
        setKeyword({ first: adsKeyword });
        return;
      }
      setKeyword({ final: adsKeyword });
    }
  }, [location.search]);

  return null;
};

export default AdsKeywordStorePage;
