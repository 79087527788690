import * as _ from 'lodash';

import { AirtableRequestForm, SharedField, SharedRequestForm } from '../airtable/RequestForm';

export class AddRequestDto extends SharedRequestForm implements AirtableRequestForm {
  get fields(): SharedField {
    return this.getFields();
  }
}

interface SharedFieldWithId extends SharedField {
  ID: string,
}

export interface AirtableRequestUpdateForm {
  fields: SharedFieldWithId
}

export class AddRequestUpdateDto extends AddRequestDto {
  private readonly id = '';

  get fields(): SharedFieldWithId {
    return _.merge(this.getFields(), {
      ID: this.id,
    });
  }
}
