import { css } from 'aphrodite';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { useSetAtom } from 'jotai';

import { InputButtonItem } from './InputButtonItem';
import Money01 from '../../../assets/svgs/money01.svg';
import Money02 from '../../../assets/svgs/money02.svg';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { closedPage, goNext, useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { requestRocketState } from '../../../shared/RocketStartContants';

export const PriceItems = React.memo(() => {
  const {isMobile, isTablet} = useRecoilValue(totalScreenState);
  const setRequest = useSetAtom(requestRocketState);
  const history = useMyHistory();

  const items = [
    {
      imageSrc: Money01,
      title: '무료 법인설립',
      before: '45만원',
      after: '0원',
      detail: '전문세무사 연결',
      onClick: () => {
        history.replace(closedPage);
        setRequest({ requestType: '무료' });
        goNext(history, {
          requestType: '무료'
        });
      },
    },
    {
      imageSrc: Money02,
      title: '유료 법인설립',
      before: '45만원',
      after: '최저수수료',
      detail: '',
      onClick: () => {
        history.replace(closedPage);
        setRequest({ requestType: '유료' });
        goNext(history, {
          requestType: '유료'
        });
      },
    },
  ];

  const styles = Styles({isMobile, isTablet});

  return (
    <>
      <div
        id={'input_items'}
        className={css(styles.selection)}
      >
        {items.map((item, index) => (
          <InputButtonItem
            {...item}
            key={'input-item-' + index}
          />
        ))}
      </div>
    </>
  );
});

const Styles = ({isMobile, isTablet}: { isMobile: boolean, isTablet: boolean }) => CreateStyle({
  selection: {
    width: '360px/342px@418px',
    height: '185px/@213px',

    display: 'flex',
    justifyContent: 'space-between',
  },
}, isMobile, isTablet);
