export interface ITaxReturn {
  name: string,
  phone: string,
  ssn: string,
  spreadSheetId?: string,
}

export interface ITaxReturnResult {
  id: string,
  state: string,
}


export interface ITaxReturnRequest {
  id: string,
  name: string,
  phone: string,
  ssn: string,
}

export type TaxReturn = ITaxReturnRequest | undefined;

export const TAX_RETURN_REQUEST_REPOSITORY = Symbol('TAX_RETURN_REQUEST_REPOSITORY');

export interface TaxReturnReopository {
  add(info: ITaxReturn): Promise<string>;
  update(info: ITaxReturnResult): Promise<string>;
  findOneById(id: string): Promise<TaxReturn>;
}
