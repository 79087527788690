import { rocketContentBlack } from '../assets/ColorContants';
import { CreateStyle } from '../../shared/presentation/shared/CreateStyle';

export const ButtonsStyles = (isMobile: boolean, isTablet: boolean, baseColor: string = 'black') => CreateStyle({
  button__custom: {
    borderLeft: 'none',

    fontSize: '/15px',
    lineHeight: '/20px',
    color: isMobile || isTablet ? undefined : rocketContentBlack,
    fill: isMobile || isTablet ? baseColor : rocketContentBlack,
    ':hover': {
      backgroundColor: '#EAEAEA',
      borderRadius: '5px/10px',
      transition: '0.3s',
    },

    ':active': {
      '-webkit-tap-highlight-color': 'transparent',
      backgroundColor: '#F5F5F5',
      borderRadius: '5px/10px',
    },

    display: 'flex',
    alignItems:'center',
    justifyContent: 'center',
  },
  button__call: {
    width: '44px/200px@50px',
    height: '44px/50px@50px',
    padding: '0/0 15px',
    marginRight: '/@25px',
  },
  button__kakao: {
    width: '44px/158px@50px',
    height: '44px/50px@50px',
    padding: '0/0 22px',
  },
  button__menu: {
    width: '44px/@50px',
    height: '44px/@50px',
    padding: '0 22px/',
  },
  button__icon: {
    width: '32px/32px@40px',
    height: '32px/32px@40px',

    fill: 'inherit',
  },
  button__kakaoText: {
    marginLeft: '/7px',
  },
  button__textAfterIcon: {
    marginLeft: '/4px',
  },
}, isMobile, isTablet);
