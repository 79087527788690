import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';

import { ReactComponent as CallLogo } from '../assets/svgs/call_L.svg';
import { LOCATION_SERVICE, LocationService } from '../../shared/store/LocationStore';
import { HeaderButtonStyles } from '../../shared/presentation/header/HeaderButtonStyle';
import { ButtonsStyles } from './CustomHeaderStyles';
import { totalScreenState } from '../../shared/presentation/MobileFrame';
import { useInjection } from '../../shared/presentation/InversifyProvider';
import { requestPage, useMyHistory } from '../../shared/presentation/HistoryHelper';

interface Props {
  baseColor?: string,
}

export function RequestButton({baseColor}: Props) {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const locationService = useInjection<LocationService>(LOCATION_SERVICE);
  const history = useMyHistory();

  const showRequestOrCall = () => {
    if (!isDesktop) {
      locationService.callToService();
      return;
    }
    history.push(requestPage());
  };

  const styles = HeaderButtonStyles({
    isMobile: isMobile,
    isTop: false,
    baseColor: baseColor ?? 'black',
  });

  const custom = ButtonsStyles(isMobile, isTablet, baseColor);
  return (
    <div
      className={css(styles.button, custom.button__custom, custom.button__call)}
      onClick={showRequestOrCall}
    >
      <CallLogo className={css(custom.button__icon)}/>
      {
        isDesktop && (
          <p
            className={css(custom.button__textAfterIcon)}
            id={'call_request_button'}
          >
            고객센터 {process.env.REACT_APP_SERVICE_CENTER_NUMBER}
          </p>
        )
      }
    </div>
  );
}

export default RequestButton;
