import React from 'react';
import { css } from 'aphrodite';
import { Link, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { scrollWindow } from '../../../shared/helper/Util';
import { ReactComponent as Title } from '../../assets/svgs/title-white.svg';
import RequestButton from '../RequestButton';
import { MenuButton } from '../MenuButton';
import { KakaoButton } from '../KakaoButton';
import { totalScreenState } from '../../../shared/presentation/MobileFrame';
import { defaultPage, IHistory, isMain, requestPage } from '../../../shared/presentation/HistoryHelper';
import { CreateSingle, CreateStyle } from '../../../shared/presentation/shared/CreateStyle';
import { rocketOrange, rocketWhite } from '../../assets/ColorContants';
import { ReactComponent as ShortArrow } from '../../assets/svgs/arrow-short.svg';
import { isTopState } from '../../../shared/presentation/ScrollFrame';

interface Props {
  noRequest?: boolean
}

export function Header({noRequest}: Props) {
  const {isMobile, isDesktop, isTablet, isTop} = useRecoilValue(totalScreenState);
  const {isUp} = useRecoilValue(isTopState);
  const history = useHistory<IHistory>();


  const custom = HeaderStyle(isMobile, isTablet);
  const isShowHeader = isUp || isTop;

  const logoFill = CreateSingle({
    fill: (isTop && isDesktop && !noRequest) ? rocketWhite : rocketOrange,
  }, isMobile, isTablet);

  const backgroundStyle = CreateSingle({
    backgroundColor: isTop ? 'transparent' : 'white',
    borderBottom: isTop && !noRequest ? undefined : '1px solid #EBEBEB',
    top: isShowHeader ? 0 : '-68px/0@-70px',
    opacity_: isShowHeader ? 1 : '0/1@0',
  }, isMobile, isTablet);

  const buttonsHidden = CreateSingle({
    transform: '/translateX(215px)'
  }, isMobile, isTablet);

  const moveToHome = () => {
    if (isMain(history)) {
      scrollWindow('main');
      return;
    }

    history.push(defaultPage);
    window.scrollTo(0, 0);
  };

  const baseColor = process.env.REACT_APP_BASE_COLOR ?? 'black';

  return (
    <div className={css(custom.background, backgroundStyle)} id={'header'}>
      <div className={css(custom.logoDiv)}>
        <Title
          id={'rocketstart_logo'}
          className={css(custom.logoTitle, logoFill)}
          onClick={moveToHome}
        />
      </div>
      <div className={css(custom.buttons, (isTop || noRequest) && buttonsHidden)}>
        <RequestButton
          baseColor={!isDesktop ? rocketOrange : baseColor}
        />
        <KakaoButton baseColor={baseColor}/>
        <MenuButton
          baseColor={!isDesktop ? rocketOrange : baseColor}
          isMobile={isMobile}
          isTablet={isTablet}
        />
        {isDesktop && (
          <Link
            className={css(custom.menuDiv)}
            to={requestPage()}
            id={'request_button'}
            rel={'kakao_request'}
          >
            <div className={css(custom.button_title)}>법인설립 신청하기</div>
            <ShortArrow className={css(custom.short_arrow)}/>
          </Link>
        )}
      </div>
    </div>
  );
}

export const HeaderStyle = (isMobile: boolean, isTablet: boolean) => CreateStyle({
  background: {
    width: '100vw',
    height: '68px/82px@70px',

    backgroundColor: 'transparent',
    position: 'fixed',
    top: '-68px/0@-70px',
    opacity_: '0/1@0',
    left: 0,

    zIndex: 50,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    paddingLeft: '27px/61px@37px',
    paddingRight: '16px/40px@23px',

    fill: rocketOrange,
    color: rocketOrange,
  },
  logoTitle: {
    width: '79px/100px@89px',
    height: '21px/24px@23px',
    objectFit: 'contain',
    fill: rocketOrange,
  },
  logoDiv: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    gap: '11px/24px@',

    height: '100%',
    transition: 'all 0.3s',
  },
  menuDiv: {
    width: '/166px',
    height: '/48px',
    backgroundColor: '#FFF5EF',

    borderRadius: '/10px',
    paddingLeft: '/6px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    whiteSpace: 'pre',

    cursor: 'pointer',
    transition: 'all 0.3s',

    ':hover': {
      backgroundColor: '#FFECE1',
      transition: '0.3s',
    },
  },
  button_title: {
    color: rocketOrange,
    fontFamily: 'Spoqa',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '/15px',
    lineHeight: '/19px',
  },
  short_arrow: {
    width: '/13px',
    height: '/13px',
    marginLeft: '/10px',
  }
}, isMobile, isTablet);
