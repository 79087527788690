import React, { useCallback, useEffect } from 'react';
import { atom, DefaultValue, selector, useSetRecoilState } from 'recoil';
import * as _ from 'lodash';

import { getDeviceType } from '../helper/Util';
import { isTopState } from './ScrollFrame';

export const totalScreenState = selector<{
  isMobile: boolean,
  isTablet: boolean,
  isDesktop: boolean,
  isTop: boolean,
  height: number,
  width: number,
}>({
  key: 'deviceStates',
  get: ({get}) => {
    const isMobile = get(isMobileState);
    const isTablet = get(isTabletState);
    const isDesktop = !isMobile && !isTablet;
    const {width, height} = get(windowState);
    const {isTop} = get(isTopState);
    return {
      width,
      height,
      isMobile,
      isTablet,
      isDesktop,
      isTop,
    };
  },
  set: ({set, reset}, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(isMobileState);
      reset(isTabletState);
      reset(windowState);
      reset(isTopState);
      return;
    }
    set(isMobileState, newValue.isMobile);
    set(isTabletState, newValue.isTablet);
    set(windowState, {
      width: newValue.width,
      height: newValue.height,
    });
  }
});

export const isMobileState = atom<boolean>({
  key: 'isMobileState',
  default: getDeviceType() === 'mobile',
});

export const isTabletState = atom<boolean>({
  key: 'isTabletState',
  default: getDeviceType() === 'tablet',
});

export const windowState = atom<{ width: number, height: number }>({
  key: 'windowState',
  default: {
    width: window?.innerWidth ?? 0,
    height: window?.innerHeight ?? 0,
  },
});

export const MobileFrame = () => {
  const setTotal = useSetRecoilState(totalScreenState);
  const resetIsMobile = useCallback(() => {
    const devType = getDeviceType();
    setTotal({
      isMobile: devType === 'mobile',
      isTablet: devType === 'tablet',
      width: window?.innerWidth ?? 0,
      height: window?.innerHeight ?? 0,
      isTop: false,
      isDesktop: false,
    });
  }, [setTotal]);

  // useEffect(() => {
  //   socket.on('users', msg => {
  //     console.log(msg);
  //   })
  // }, [])

  useEffect(() => {
    resetIsMobile();
    window?.addEventListener('resize', _.throttle(resetIsMobile, 100));
    window?.addEventListener('orientationchange', _.throttle(resetIsMobile, 100))

    return () => {
      window?.removeEventListener('resize', _.throttle(resetIsMobile, 100));
      window?.removeEventListener('orientationchange', _.throttle(resetIsMobile, 100))
    };
  }, [resetIsMobile]);

  return (<></>);
};
