import { atom } from 'recoil';

import { PrivacyPolicyNotCheckedError } from './PrivacyPolicyNotCheckedError';
import { InvalidRequestError } from './InvalidRequestError';

export const STATES = [
  '서울특별시',
  '부산광역시',
  '대구광역시',
  '인천광역시',
  '광주광역시',
  '대전광역시',
  '울산광역시',
  '경기도',
  '강원도',
  '충청북도',
  '충청남도',
  '전라북도',
  '전라남도',
  '경상북도',
  '경상남도',
  '제주특별자치도',
];

export const CITIES = [
  [
    '송파구', '강서구', '노원구', '강남구', '관악구', '은평구', '양천구', '성북구',
    '서초구', '강동구', '구로구', '중랑구', '동작구', '마포구', '영등포구', '광진구',
    '동대문구', '도봉구', '강북구', '서대문구', '성동구', '금천구', '용산구', '종로구', '중구'
  ],
  [
    '부산진구','사하구','북구','남구','동래구'
    ,'금정구','사상구','연제구','수영구','기장구'
    ,'영도구','서구','강서구','동구','중구'
  ],
  [
    '달서구','수성구','북구','동구','서구','남구','중구'
  ],
  [
    '서구','남동구','부평구','미주홀구','연수구'
    ,'계양구','중구','강화군','동구','웅진군'
  ],
  [
    '북구','광산구','서구','남구','동구'
  ],
  [
    '서구','유성구','중구','동구','대덕구'
  ],
  [
    '중구','남구','동구','북구','울주군'
  ],
  [
    '수원시', '고양시', '용인시', '성남시', '부천시', '안산시', '남양주시',
    '화성시', '안양시', '평택시', '의정부', '파주시', '시흥시', '김포시',
    '광명시', '광주시', '군포시', '하남시', '이천시', '오산시',
  ],
  [
    '춘천시', '원주시', '강릉시', '동해시', '태백시', '속초시',
    '삼척시', '홍천군', '횡성군', '영월군', '평창군', '정선군',
    '철원군', '화천군', '양구군', '인제군', '고성군', '양양군',
  ],
  [ '청주시', '충주시', '제천시', '보은군', '옥천군', '영동군', '증평군', '진천군', '괴산군', '음성군', '단양군', ],
  [
    '천안시', '공주시', '보령시', '아산시', '서산시', '논산시', '계룡시',
    '당진시', '금산군', '부여군', '서천군', '청양군', '홍성군', '예산군', '태안군',
  ],
  [
    '전주시', '군산시', '익산시', '정읍시', '남원시', '김제시', '완주군',
    '진안군', '무주군', '장수군', '임실군', '순창군', '고창군', '부안군',
  ],
  [
    '목포시', '여수시', '순천시', '나주시', '광양시', '담양군', '곡성군',
    '구례군', '고흥군', '보성군', '화순군', '장흥군', '강진군', '해남군',
    '영암군', '무안군', '함평군', '영광군', '장성군', '완도군', '진도군', '신안군',
  ],
  [
    '포항시', '경주시', '김천시', '안동시', '구미시', '영주시', '영천시', '상주시',
    '문경시', '경산시', '군위군', '의성군', '청송군', '영양군', '영덕군', '청도군',
    '고령군', '성주군', '칠곡군', '예천군', '봉화군', '울진군', '울릉군',
  ],
  [
    '창원시', '진주시', '통영시', '사천시', '김해시', '밀양시', '거제시', '양산시', '의령군',
    '함안군', '창녕군', '고성군', '남해군', '하동군', '산청군', '함양군', '거창군', '합천군',
  ],
  [
    '제주시', '서귀포시',
  ],
];

export interface IPartnershipForm {
  company: string,
  name: string,
  phone: string,
  email: string,
  address01Index?: number,
  address02Index?: number,
  question: string,
  isPrivacyPolicyChecked: boolean,
}

export class PartnerShipForm {
  company = ''
  name = '';
  phone = '';
  email = '';
  address01Index?: number;
  address02Index?: number;
  question = '';
  isPrivacyPolicyChecked = true;

  static checkIsValid(request: IPartnershipForm) {
    if (!request.isPrivacyPolicyChecked) {
      throw new PrivacyPolicyNotCheckedError();
    }

    if (request.company === '' || request.name === '' || request.phone === '' || request.email === '') {
      throw new InvalidRequestError();
    }

    if (request.address01Index === undefined || request.address02Index === undefined) {
      throw new InvalidRequestError();
    }
  }

  static getAddress01(request: IPartnershipForm): string {
    return request.address01Index != null ? STATES[request.address01Index] : '선택 없음';
  }

  static getAddress02(request: IPartnershipForm): string {
    return request.address01Index != null && request.address02Index != null
      ? CITIES[request.address01Index][request.address02Index]
      : '선택 없음';
  }
}

export const partnershipFormState = atom<IPartnershipForm>({
  key: 'partnershipFormState',
  default: new PartnerShipForm(),
})
