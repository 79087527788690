import axios from 'axios';
import 'reflect-metadata';
import { inject, injectable } from 'inversify';
import Cookies from 'universal-cookie';
import { plainToClass } from 'class-transformer';

import { REQUEST_REPOSITORY, RequestRepository } from '../domain/RequestRepository';
import { KAKAO_MESSAGE_SENDER, KakaoMessageSender } from '../domain/KakaoMessageSender';
import { PHONE_FORMAT_POLICY, PhoneFormatPolicy, TypicalPhoneFormatPolicy } from '../domain/PhoneFormatPolicy';
import { IPartnershipForm, PartnerShipForm } from './PartnershipStore';
import { getDeviceType, mapDeviceToKorean, sleep } from '../helper/Util';
import { SCRIPT_LOGGER, ScriptLogger } from '../domain/ScriptLogger';
import { isEmptyCheck } from '../domain/CommonPolicy';
import { AddPartnershipRequestDto } from '../infra/AddPartnershipRequestDto';
import { AddRequestDto, AddRequestUpdateDto } from '../infra/AddRequestDto';


export interface RequestPageManager {
  isShowRequestPopup(): boolean;

  showRequestPopup(): void;

  closePopups(): void;

  isInputProcess(): boolean;

  isCheckProcess(): boolean;

  isSuccessProcess(): boolean;

  showRequestCheckPopup(): void;

  showRequestSuccessPopup(): void;
}

export interface IRequestForm {
  name: string,
  phone: string,
  question: string,
  isPrivacyPolicyChecked: boolean,
  bizType?: string,
  requestType?: string,
  type: string;
}

export interface IRequestError {
  isNameError?: boolean,
  isPhoneEmpty?: boolean,
  isPhoneError?: boolean,
  isQuestionError?: boolean,
  isBizTypeError?: boolean
}

export class RequestError {
  isNameError = false;
  isPhoneEmpty = false;
  isPhoneError = false;
  isQuestionError = false;
  isBizTypeError = false;

  static create(
    {
      isNameError = false,
      isPhoneEmpty = false,
      isPhoneError = false,
      isQuestionError = false,
      isBizTypeError = false,
    }: IRequestError): RequestError {
    const errors = new RequestError();
    errors.isNameError = isNameError;
    errors.isPhoneEmpty = isPhoneEmpty;
    errors.isPhoneError = isPhoneError;
    errors.isQuestionError = isQuestionError;
    errors.isBizTypeError = isBizTypeError;
    return errors;
  }

  isValid(): boolean {
    return !this.isNameError && !this.isPhoneEmpty && !this.isPhoneError && !this.isQuestionError && !this.isBizTypeError;
  }
}

export const REQUEST_SERVICE = Symbol('REQUEST_SERVICE');

@injectable()
export class RequestService {
  @inject(PHONE_FORMAT_POLICY)
  private readonly phoneFormatPolicy!: PhoneFormatPolicy;

  @inject(REQUEST_REPOSITORY)
  private readonly requestRepository!: RequestRepository;

  @inject(KAKAO_MESSAGE_SENDER)
  private readonly kakaoMessageSender!: KakaoMessageSender;

  @inject(SCRIPT_LOGGER)
  private readonly scriptLogger!: ScriptLogger;

  checkRequest(request: IRequestForm): RequestError {
    return RequestError.create({
      isNameError: request.name === '',
      isPhoneEmpty: request.phone === '',
      isPhoneError: !this.phoneFormatPolicy.isValid(request.phone),
      isQuestionError: request.question === '',
      isBizTypeError: request.bizType === '' ?? false,
    });
  }

  async sendRequest(request: IRequestForm, keyword: string) {
    const form = plainToClass(AddRequestDto, {
      name: request.name,
      phone: request.phone,
      question: request.question,
      keyword,
      bizType: request.bizType ?? '',
    });
    await this.requestRepository.addRequest(form);
    this.scriptLogger.sendRequestOccuredLog();

    try {
      await this.kakaoMessageSender.sendConfirmRequestMessage(request.name, request.phone);
    } catch (e) {
      console.log(e);
    }
  }

  async getRecordId(): Promise<string> {
    const cookie = new Cookies();
    const requestStart = cookie.get('request_start');
    const recordId = cookie.get('record_id');
    if (requestStart == null || requestStart === '') {
      return '';
    }
    if (recordId != null && recordId !== '') {
      return recordId;
    }

    await sleep(1000);
    return new Cookies().get('record_id');
  }

  async createRequest(
    {request, keyword}: { request: IRequestForm, keyword: string }
  ): Promise<string> {
    const form = plainToClass(AddRequestDto, {
      name: request.name,
      phone: request.phone,
      question: request.question,
      keyword,
      bizType: request.bizType ?? '',
    });

    const recordId = await this.requestRepository.addRequest(form);
    const cookie = new Cookies();
    cookie.set('record_id', recordId, {path: '/', maxAge: 60 * 30});
    cookie.set('request_start', new Date(), {path: '/', maxAge: 60 * 30});
    return recordId;
  }

  async sendRequestWithRecordId(request: IRequestForm, keyword: string, cookie: Cookies): Promise<string> {
    const recordId = await this.getRecordId();
    const isExist = await this.isExistRecord(recordId);
    if (isEmptyCheck(recordId) || !isExist) {
      return this.createRequest({request, keyword});
    }
    const updateForm = plainToClass(AddRequestUpdateDto, {
      id: recordId,
      name: request.name,
      phone: request.phone,
      question: request.question,
      keyword,
      bizType: request.bizType ?? '',
    });
    await this.requestRepository.updateRequest(updateForm);

    cookie.set('record_id', recordId, {path: '/', maxAge: 60 * 30});
    return recordId;
  }

  async sendFinalRequestWithRecordId(request: IRequestForm, keyword: string, cookie: Cookies) {
    const result = await this.sendRequestWithRecordId(request, keyword, cookie);
    if (!result) {
      return new Error('정보 업데이트가 되지 않았습니다.');
    }

    this.scriptLogger.sendRequestOccuredLog();
    try {
      await this.kakaoMessageSender.sendConfirmRequestMessage(request.name, request.phone);
      cookie.remove('record_id', {path: '/'});
      cookie.remove('request_start', {path: '/'});
    } catch (e) {
      console.log(e);
    }
  }

  async sendSavetaxRequest(request: IRequestForm) {
    const deviceType = mapDeviceToKorean(getDeviceType());
    const inquireType = '일반';
    const name = request.name;
    const phone = request.phone;
    const comment = request.question;

    await axios.post(
      process.env.REACT_APP_INQUIRE_API_URL!,
      {
        deviceType,
        inquireType,
        name,
        phone,
        comment,
      },
    );
  }

  async sendPartnerShipRequest(request: IPartnershipForm) {
    const form = plainToClass(AddPartnershipRequestDto, {
      companyName: request.company,
      userName: request.name,
      phone: request.phone,
      email: request.email,
      address: `${PartnerShipForm.getAddress01(request)} - ${PartnerShipForm.getAddress02(request)}`,
      question: request.question,
    });
    await this.requestRepository.addPartnership(form);
    await this.kakaoMessageSender
      .sendConfirmRequestMessageForPartnership(request.name, request.phone);
  }

  async isExistRecord(recordId: string) {
    let result = '';
    try {
      result = await this.requestRepository.findExistByRecordId(recordId);
    } catch (e) {
      return false;
    }
    return !isEmptyCheck(result);
  }

  setPhoneNumber(phone: string)
    : { result?: string, error?: { isPhoneError: boolean, isPhoneEmpty: boolean } } {
    const phoneFormatPolicy = new TypicalPhoneFormatPolicy();

    if (phoneFormatPolicy.isCompletedNumber(phone)) {
      return {
        error: {
          isPhoneError: false,
          isPhoneEmpty: false,
        }
      };
    }

    if (phoneFormatPolicy.hasInvalidForm(phone)) {
      return {
        error: {
          isPhoneError: true,
          isPhoneEmpty: false,
        }
      };
    }

    return {
      result: phoneFormatPolicy.changeFormat(phone),
    };
  }

}
