import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { css } from 'aphrodite';
import { useParams } from 'react-router-dom';
import { useAtomValue, useAtom } from 'jotai';
import Cookies from 'universal-cookie';

import { CreateSingle, CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { Step01 } from './Step01';
import { Step02 } from './Step02';
import { Step03 } from './Step03';
import { Step04 } from './Step04';
import { isShowRequestPopup, requestPage, save, useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { requestRocketState } from '../../../shared/RocketStartContants';
import { Step02Mobile } from './Step02Mobile';
import { Step03Mobile } from './Step03Mobile';
import { animationStyle } from '../../../shared/SharedAni';
import { MobileRequestHeader } from '../../../shared/header/MobileRequestHeader';
import { Header } from '../../../shared/header/Header';
import { rocketContentBlack } from '../../../assets/ColorContants';
import { isEmptyCheck } from '../../../../shared/domain/CommonPolicy';
import { useInjection } from '../../../../shared/presentation/InversifyProvider';
import { PHONE_FORMAT_POLICY, PhoneFormatPolicy } from '../../../../shared/domain/PhoneFormatPolicy';
import { CustomRequestService } from '../../../../shared/service/RequestService';
import { totalKeywordAtom } from '../../../../shared/presentation/KeywordAtom';

export const stepViewAnimationStyle = CreateSingle({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}, false);

const scrollElementIntoView = (ref: React.RefObject<HTMLDivElement>) => {
  const element = ref.current;
  if (!element) {
    return;
  }
  let scrollTop = window.pageYOffset || element.scrollTop;

  const headerOutsideIframe = isIOSDevice() ? 154 : element.clientHeight;
  const finalOffset = element.getBoundingClientRect().top + scrollTop + headerOutsideIframe;

  window.parent.scrollTo({
    top: finalOffset,
    behavior: 'smooth',
  });
};

const isIOSDevice = () => {
  const userAgent = navigator.userAgent;
  return (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipad') > -1 || userAgent.indexOf('ipod') > -1);
};

export function RequestInput() {
  const { isMobile, isTablet, isDesktop } = useRecoilValue(totalScreenState);
  const phoneFormatPolicy = useInjection<PhoneFormatPolicy>(PHONE_FORMAT_POLICY);
  // const requestService = useInjection<RequestService>(REQUEST_SERVICE);
  const requestService = useInjection(CustomRequestService);
  const { progress } = useParams<any>();
  const ref = useRef<any>(null);
  const history = useMyHistory();
  const [request, setRequest] = useAtom(requestRocketState);
  const refScroll = useRef<HTMLDivElement>(null);
  const [seconds, setSeconds] = useState(Number(window.localStorage.getItem('count')) ?? 0);
  const keyword = useAtomValue(totalKeywordAtom);
  const cookie = new Cookies();

  const onScrollUp = useCallback(() => {
    setTimeout(
      () => scrollElementIntoView(refScroll),
      300
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      history.location.state?.requestType == null
    ) {
      history.replace(requestPage(0));
      return;
    }
    setRequest(history.location.state);
  }, []);

  const callSendRequest = async () => {
    await save(history, request);
    sendRequest();
  };

  const sendRequest = () => {
    if (!isEmptyCheck(request.phone)) {
      requestService?.sendRequest(request, keyword);
      window.localStorage.clear();
    }
  };

  useEffect(() => {
    const isValid = !isEmptyCheck(request.name) && (!isEmptyCheck(request.phone) && phoneFormatPolicy.isValid(request.phone));
    const countTime = () =>
      setTimeout(() => {
        if (seconds > -1 && seconds <= 600) {
          setSeconds(seconds + 1);
          window.localStorage.setItem('count', String(seconds));

          if (isValid && seconds === 600) {
            callSendRequest();
          }
        }
      }, 1000);
    countTime();
    return () => clearTimeout(countTime());
  }, [seconds, request]);

  const styles = Styles({ isMobile, isTablet });
  return (
    <div
      className={css(
        !isDesktop
        && isShowRequestPopup(history)
        && animationStyle(0, 0).popUp
      )}
      ref={refScroll}
      id={'request_page'}
    >
      {isDesktop ? <Header noRequest/> : <MobileRequestHeader/>}
      <div
        className={css(styles.background)} onClick={() => ref.current?.onClickBackground?.()}
        id={'request_content_page'}
      >
        <div className={css(styles.title)}>
          법인설립 신청하기
        </div>
        <MapProgressToStepView
          progress={progress}
          isDesktop={isDesktop}
          refObject={ref}
          onScrollUp={onScrollUp}
          cookie={cookie}
        />
      </div>
    </div>
  );
}

const MapProgressToStepView = (input: {
  progress: string,
  isDesktop: boolean,
  refObject: MutableRefObject<any>
  onScrollUp: () => void,
  cookie: Cookies,
}) => {
  const { progress, isDesktop, refObject, onScrollUp, cookie } = input;
  switch (progress) {
    case '0':
      return <Step01/>;
    case '1':
      return isDesktop ? <Step02 onScrollUp={onScrollUp} cookie={cookie}/> : <Step02Mobile onScrollUp={onScrollUp}/>;
    case '2':
      return isDesktop ? <Step03 ref={refObject} onScrollUp={onScrollUp}/> :
        <Step03Mobile onScrollUp={onScrollUp} cookie={cookie}/>;
    case '3':
      return isDesktop ? <Step04 onScrollUp={onScrollUp} cookie={cookie}/> :
        <Step03 ref={refObject} onScrollUp={onScrollUp}/>;
    case '4':
      return <Step04 onScrollUp={onScrollUp} cookie={cookie}/>;
    default:
      return <Step01/>;
  }
};

const Styles = ({ isMobile, isTablet }: { isMobile: boolean, isTablet: boolean }) => CreateStyle({
  background: {
    width: '100%',
    background: 'white',

    paddingTop: '76px/192px@96px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    fontFamily: 'Spoqa',
    fontSize: '28px/26px@32px',
    lineHeight: '43px/40px@62px',

    transition: '.1s',
  },
  title: {
    marginBottom: '5px/12px@8px',
    fontWeight: 'bold',
    color: rocketContentBlack,
  },
}, isMobile, isTablet);
