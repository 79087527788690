import React, {createContext, useContext, useMemo} from 'react';
import useMedia from 'use-media';

interface Props {
  children: React.ReactNode;
}

interface IMobileFrame { isMobile: boolean, isTablet: boolean, isDesktop: boolean, prefersReducedMotion: boolean };

export const MediaQueryContext = createContext<IMobileFrame>({
  isMobile: false,
  isTablet: false,
  isDesktop: true,
  prefersReducedMotion: false,
});

const mediaQueries = {
  mobile: `(max-width: ${process.env.REACT_APP_MOBILE_WIDTH}px)`, //'(max-width: 767px)',
  tablet: `(max-width: ${process.env.REACT_APP_TABLET_WIDTH}px)`,
  prefersReducedMotion: '(prefers-reduced-motion: reduce)',
};

export default function MediaQueryProvider({children}: Props) {
  const isMobile = useMedia(mediaQueries.mobile);
  const isTablet = useMedia(mediaQueries.tablet);
  const prefersReducedMotion = useMedia(mediaQueries.prefersReducedMotion);
  const value = useMemo(() => ({
    isMobile,
    isTablet: !isMobile && isTablet,
    isDesktop: !isMobile && !isTablet,
    prefersReducedMotion
  }), [isMobile, isTablet, prefersReducedMotion]);

  return (
    <MediaQueryContext.Provider value={value}>
      {children}
    </MediaQueryContext.Provider>
  );
}

export function useMediaQueryContext() {
  return useContext(MediaQueryContext);
}
