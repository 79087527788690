import { CreateSingle } from '../../shared/presentation/shared/CreateStyle';
import { useRecoilValue } from 'recoil';
import { totalScreenState } from '../../shared/presentation/MobileFrame';
import { rocketOrange } from '../assets/ColorContants';
import { css } from 'aphrodite';
import React from 'react';

interface Props {
  width?: string,
  height?: string,
  paddingTop?: string,
  paddingBottom?: string,
  borderRadius?: string,
  fontSize?: string,
  color?: string,
  backgroundColor?: string,
  marginBottom?: string,
  children?: any,
  onClick?: () => void,
  id?: string,
}

export function RocketButton(input: Props) {
  const {isMobile, isTablet} = useRecoilValue(totalScreenState);
  const {onClick, id, ...styles} = input;
  const buttonStyle = CreateSingle({
    width: '/94px',
    height: '/48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '/10px',
    fontWeight: 'bold',
    fontSize: '/16px',
    lineHeight: '/24px',
    backgroundColor: rocketOrange,
    color: 'white',
    letterSpacing: '-0.02em',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: '#FF8F4F',
    },
    ':active': {
      transform: 'scale(0.98)',
    },
    ...styles,
  }, isMobile, isTablet);
  return (
    <div
      id={id}
      className={css(buttonStyle)}
      onClick={onClick}
    >
      {input.children}
    </div>
  );

}
