import { currentDateString } from '../helper/Util';

export interface SharedPartnerField {
  CREATE_DATE: string;
  NAME: string,
  TEL: string;
  COMMENT: string;
}

export interface PartnerForm {
  fields: SharedPartnerField;
}

export class SharedPartnerForm {
  public companyName = '';
  public userName = '';
  public phone = '';
  public email = '';
  public question = '';
  public address = '';

  protected getFields(): SharedPartnerField {
    return {
      CREATE_DATE: currentDateString(),
      NAME: `${this.companyName} / ${this.userName}`,
      TEL: this.phone,
      COMMENT: this.address.length > 0 ? `${this.email} / ${this.address} / ${this.question}` : `${this.email} / ${this.question}`,
    };
  }

  setProps(props: Partial<SharedPartnerForm>): this {
    this.companyName = props.companyName ?? this.companyName;
    this.userName = props.userName ?? this.userName;
    this.phone = props.phone ?? this.phone;
    this.email = props.email ?? this.email;
    this.question = props.question ?? this.question;
    return this;
  }
}
