export interface AddBuildRequestDto {
  name: string,
  phone: string,
  email: string,
  companyType: string[],
  corporationType: string,
  place: string,
  office: string,
  corporateName: string,
  corporateNameInEnglish?: string,
  capitalStock: string,
  freeAgency: string,
  contractAgreement: boolean,
  priceAgreement: boolean,
  utilityCharge: string,
  keyword?: string,
}

export interface IBuildRequest extends AddBuildRequestDto {
  status: string,
  proceedMethod: string,
  taxAccountant: string,
  lawyer: string,
  profileCompanyType: string,
}

export class BuildExpert {
  name: string = '';
  ageGroup?: string;
  companyName: string = '';
  phone: string = '';
  private virtualPhone?: string;
  address: string = '';
  career: string = '';
  introduce: string = '';
  photo: any;
  majorCompanyType?: string[];

  setVirtualPhone(value: string): void {
    this.virtualPhone = value;
  }

  get showingPhone(): string {
    return this.virtualPhone ?? this.phone;
  }
}

export interface BuildInfo {
  content: string;
}

export const BUILD_REQUEST_REPOSITORY = Symbol('BUILD_REQUEST_REPOSITORY');

export interface BuildRepository {
  addRequest(request: AddBuildRequestDto): Promise<string>;
  findDataByRecordId(recordId: string): Promise<IBuildRequest | undefined>;
  findTaxAccountantDataByKey(key: string): Promise<BuildExpert | undefined>;
  findLawyerDataByKey(key: string): Promise<BuildExpert>;
  getInfo(type: string): Promise<string>;
}