// @ts-nocheck
import { ConversionLogger } from '../domain/ConversionLogger';

export class FacebookPixelLogger implements ConversionLogger {
  logConversionScript() {
    window.fbq('track', 'Contact');

    /* meta 픽셀 전환 스크립트 */
    window.fbq('track', 'CompleteRegistration', {
      value: 1,
      currency: '1',
    });
  }
}
