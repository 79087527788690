import { css } from 'aphrodite';
import React, { useCallback, useEffect, useRef } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import Cookies from 'universal-cookie';

import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { rocketContentBlack, rocketOrange } from '../../../assets/ColorContants';
import { RocketInput } from './RocketInput';
import { RocketProgressFooter } from './RocketProgressFooter';
import { requestRocketAtom, requestRocketState } from '../../../shared/RocketStartContants';
import { goNext, save, useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { useInjection } from '../../../../shared/presentation/InversifyProvider';
import { PHONE_FORMAT_POLICY, PhoneFormatPolicy } from '../../../../shared/domain/PhoneFormatPolicy';
import { animationStyle } from '../../../shared/SharedAni';
import { stepViewAnimationStyle } from './RequestInput';
import { SCRIPT_LOGGER, ScriptLogger } from '../../../../shared/domain/ScriptLogger';
import { isEmptyCheck } from '../../../../shared/domain/CommonPolicy';
import { CustomRequestService } from '../../../../shared/service/RequestService';
import { totalKeywordAtom } from '../../../../shared/presentation/KeywordAtom';

export const Step02 = ({ onScrollUp, cookie }: { onScrollUp: () => void, cookie: Cookies }) => {
  const request = useAtomValue(requestRocketAtom);
  const setRequest = useSetAtom(requestRocketState);
  const phoneFormatPolicy = useInjection<PhoneFormatPolicy>(PHONE_FORMAT_POLICY);
  const history = useMyHistory();
  const scriptLogger = useInjection<ScriptLogger>(SCRIPT_LOGGER);
  const footerRef = useRef<any>(null);
  const nameInputRef = useRef<any>(null);
  const phoneInputRef = useRef<any>(null);
  // const requestService = useInjection<RequestService>(REQUEST_SERVICE);
  const requestService = useInjection(CustomRequestService);
  const keyword = useAtomValue(totalKeywordAtom);

  const mapTooltipMessage = (input: { name?: string, phone?: string }): [string, boolean] => {
    const { name = '', phone = '' } = input;
    if (name === '') {
      return ['이름을 입력해주세요.', true];
    }

    if (phone === '' || !phoneFormatPolicy.isValid(request.phone)) {
      return ['연락처를 입력해주세요.', true];
    }

    return ['', false];
  };
  const [tooltipMsg, isDisabled] = mapTooltipMessage(request);

  // const sendRequest = async () => {
  //   if (!isEmptyCheck(request.phone)) {
  //     const recordId = await requestService?.sendRocketStartRequest(mapToRequestForm(request), keyword, cookie);
  //   }
  // };

  useEffect(() => scriptLogger?.sendPathApproachedLog('/request/1'), []);

  const onClickNext = useCallback(async () => {
    await save(history, request);
    if (!isEmptyCheck(request.phone)) {
      requestService?.sendRequest(request, keyword);
    }
    await goNext(history, request);
  }, [request, history]);

  const onChangeHandler = (input: { name?: string, phone?: string }) => setRequest(input);

  const handleKeyPress = async (e: any) => {
    if (e.key === 'Enter') {
      await onClickNext();
    } else {
      handleBlur();
    }
  };

  const handleBlur = () => footerRef.current?.hideMessage();

  const styles = Styles();
  return (
    <div
      className={css(
        stepViewAnimationStyle,
        animationStyle(0, 0).popUp
      )}>
      <div className={css(styles.description)}>
        어떤분이신지 궁금해요 <span role={'img'} aria-label="hands-up">🙌</span>
      </div>
      <RocketInput
        addStyle={styles.space}
        placeholder={'이름'}
        onChange={value => setRequest({ name: value })}
        value={request.name}
        id={'request_name'}
        onFocus={onScrollUp}
        onBlur={handleBlur}
        onKeydown={handleKeyPress}
        refObject={nameInputRef}
      />
      <RocketInput
        addStyle={styles.space_bottom}
        placeholder={'연락처'}
        onChange={value => onChangeHandler({ phone: value })}
        value={request.phone}
        id={'request_phone'}
        isNumeric={true}
        onFocus={onScrollUp}
        onBlur={handleBlur}
        onKeydown={handleKeyPress}
        refObject={phoneInputRef}
      />
      <div className={css(styles.small)}>
        * 상담 신청을 위해 남겨주신 개인정보는<br/>
        &nbsp;&nbsp; 개인정보취급방침에 의해 100% 안전하게 보호됩니다.
      </div>
      <RocketProgressFooter
        ref={footerRef}
        progress={'1'}
        onClickNext={onClickNext}
        toolTipMsg={tooltipMsg}
        isDisabled={isDisabled}
      />
    </div>
  );
};

const Styles = () => CreateStyle({
  description: {
    fontSize: '/18px',
    marginBottom: '/45px',
    color: rocketContentBlack,
  },
  orange: {
    color: rocketOrange,
    fontWeight: 'bold',
  },
  space: {
    marginBottom: '/9px'
  },
  space_bottom: {
    marginBottom: '/16px'
  },
  small: {
    width: '100%',
    fontSize: '/13px',
    lineHeight: '/20px',
    letterSpacing: '-0.02em',
    marginBottom: '/52px',
    color: '#A0A4A7',
  }
}, false);
