import * as _ from 'lodash';
import { classToClass } from 'class-transformer';

import { TypicalPhoneFormatPolicy } from '../../shared/domain/PhoneFormatPolicy';

export class TaxReturnForm {
  private readonly policy = new TypicalPhoneFormatPolicy();
  name = '';
  _phone = '';
  ssn1 = '';
  ssn2 = '';
  spreadSheetId?: string;
  recordId?: string;

  copy(): TaxReturnForm {
    return classToClass(this);
  }

  toJson(): { name: string, phone: string, socialSecurityNo: string } {
    return _.pick(this, ['name', 'phone', 'socialSecurityNo'])
  }

  setSsn1(value: string): this {
    this.ssn1 = value;
    return this;
  }

  setSsn2(value: string): this {
    this.ssn2 = value;
    return this;
  }

  setSsn(value: string): this {
    const ssn1 = value.substr(0, 6);
    const ssn2 = value.substr(6);
    this.ssn1 = ssn1;
    this.ssn2 = ssn2;
    return this;
  }

  setName(value: string): this {
    this.name = value;
    return this;
  }

  setPhone(value: string): this {
    this.phone = value;
    return this;
  }

  setSpreadSheetId(value: string): this {
    this.spreadSheetId = value;
    return this;
  }

  setRecordId(value: string): this {
    this.recordId = value;
    return this;
  }

  get phone(): string {
    return this._phone;
  }

  set phone(value: string) {
    this._phone = this.policy.changeFormat(value);
  }

  get socialSecurityNo(): string {
    return this.ssn1 + this.ssn2;
  }

  get isValid(): boolean {
    return _.chain(this)
      .pick(['name', 'phone', 'ssn1', 'ssn2'])
      .values()
      .every(v => v !== '')
      .value()
      && this.isSsn1Filled
      && this.isSsn2Filled;
  }

  get isSsn1Filled(): boolean {
    return this.ssn1.length === 6;
  }

  get isSsn2Filled(): boolean {
    return this.ssn2.length === 7;
  }

  get formErrorMessage(): string | undefined {
    if (this.isValid) {
      return;
    }

    if (this.name === '') {
      return '이름을 입력해주세요.';
    }

    if (this.phone === '') {
      return '전화번호를 입력해주세요.';
    }

    if (this.ssn1 === '' || this.ssn2 === '') {
      return '주민등록번호를 입력해주세요.';
    }

    if (!this.isSsn1Filled) {
      return '주민등록번호 앞자리를 확인해주세요.';
    }

    if (!this.isSsn2Filled) {
      return '주민등록번호 뒷자리를 확인해주세요.';
    }

    return;
  }
}
