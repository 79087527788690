import { FranchisePartnerForm } from '../infra/FranchisePartnerForm';
import { AirtableRequestForm } from '../airtable/RequestForm';
import { AirtableRequestUpdateForm } from '../infra/AddRequestDto';
import { ITotalKeyword } from '../presentation/KeywordAtom';

export const FRANCHISE_REQUEST_REPOSITORY = Symbol('FRANCHISE_REQUEST_REPOSITORY');

export interface FranchiseRequestRepository {
  addRequest(request: AirtableRequestForm): Promise<string>;
  addRequestForPartner(request: FranchisePartnerForm, keyword: ITotalKeyword): Promise<string>;
  updateRequest(request: AirtableRequestUpdateForm): Promise<string>;
  findExistByRecordId(recordId: string): Promise<any>;
}
