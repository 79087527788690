import React from 'react';

import { adjustImage, ImageProps } from './Image';
import { getBrowser, isEdgeLegacy } from '../helper/Util';

export function SvgImage({src, ref, alt, ...props}: ImageProps) {
  const isNaver = getBrowser() === 'Naver';

  if (isNaver || isEdgeLegacy()) {
    return (
      <img src={adjustImage(src)} alt={alt} {...props}/>
    );
  }

  return (
    <object
      type='image/svg+xml'
      data={adjustImage(src)}
      {...props}
      style={{pointerEvents: 'none'}}
    >
      <img src={adjustImage(src)} alt={alt}/>
    </object>
  );
}
