import React from 'react';
import { css } from 'aphrodite';
import { Link } from 'react-router-dom';

import KakaoPopUpRocketStyle from './KakaoPopUpRocketStyle';
import { closedPage } from '../../../shared/presentation/HistoryHelper';

export function KakaoSuccessRocket() {
  const styles = KakaoPopUpRocketStyle(false);
  return (
    <React.Fragment>
      <div
        className={css(styles.kakao_comment, styles.finish_font_size, styles.finish_margin_bottom)}
        data-testid='success'
      >
        휴대폰 카카오톡으로 메시지가 전달되었습니다. <br/>
        확인부탁드립니다.
      </div>
      <Link
        className={css(styles.button)}
        type='button'
        to={closedPage}
        id={'kakao_send_button'}
      >
        확인
      </Link>
    </React.Fragment>
  );
}
