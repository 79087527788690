import * as _ from 'lodash';
import { css } from 'aphrodite';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import Hangul from 'hangul-js';

import { totalScreenState } from '../../../../shared/presentation/MobileFrame';
import { CreateStyle } from '../../../../shared/presentation/shared/CreateStyle';
import { RocketInput } from './RocketInput';
import { RocketProgressFooter } from './RocketProgressFooter';
import { ReactComponent as ChatBox } from '../../../assets/svgs/chat-box.svg';
import { ReactComponent as ChatBoxMobile } from '../../../assets/svgs/chat-box-mobile.svg';
import { getFromHistory, goNext, save, useMyHistory } from '../../../../shared/presentation/HistoryHelper';
import { stepViewAnimationStyle } from './RequestInput';
import { animationStyle } from '../../../shared/SharedAni';
import { ReactComponent as Close } from '../../../assets/svgs/close-biztype.svg';
import { rocketContentBlack, rocketOrange } from '../../../assets/ColorContants';
import { spoqa } from '../../../../shared/css/css-utils';
import { createSearchRegex } from '../../../domain/SearchPolicy';
import { BIZ_TYPE_LIST } from '../../../../startbiz/pages/customer/customer-page/BizTypesConstants';
import { useInjection } from '../../../../shared/presentation/InversifyProvider';
import { SCRIPT_LOGGER, ScriptLogger } from '../../../../shared/domain/ScriptLogger';
import { DesktopBr } from '../../../../shared/presentation/DesktopBr';
import { useAtom } from 'jotai';
import { requestRocketState } from '../../../shared/RocketStartContants';

export const Step03 = React.forwardRef(({onScrollUp}: { onScrollUp: () => void }, ref) => {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const [isFocus, setIsFocus] = useState(false);
  const [{ bizType }, setRequest] = useAtom(requestRocketState);
  const [recommends, setRecommends] = useState<string[]>([]);
  const [select, setSelect] = useState(-1);
  const refInput = useRef<HTMLInputElement>(null);
  const [keyInValue, setKeyInValue] = useState('');
  const history = useMyHistory();
  const scriptLogger = useInjection<ScriptLogger>(SCRIPT_LOGGER);
  const footerRef = useRef<any>(null);
  const bizTypes = !isDesktop ? [] : bizType.split('//').filter(value => value !== '');

  useImperativeHandle(ref, () => ({
    onClickBackground: () => {
      setIsFocus(false);
      refInput.current?.blur();
    },
  }));

  useEffect(() => {
    const bizTypeTmp = getFromHistory(history, 'bizType');
    if (bizTypeTmp != null) {
      setRequest({ bizType: bizTypeTmp });
    }
  }, [history]);

  useEffect(() => {
    scriptLogger?.sendPathApproachedLog('/request/2');
    refInput.current?.focus();
  });

  const handleEnter = (e: any) => {
    if (e.target == null) {
      return;
    }
    if (e.key === 'Enter') {
      if (isDesktop) {
        if (bizType.length > 0 && keyInValue.length === 0) {
          footerRef.current?.clickNext();
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEnter);
    return () => document.removeEventListener('keydown', handleEnter);
  }, [bizType, isFocus, keyInValue]);

  useEffect(() => {
    if (select !== -1) {
      refInput.current!.value = recommends[select];
    }
  }, [select]);

  useEffect(() => {
    if (!isFocus) {
      setKeyInValue('');
    }
  });

  useEffect(() => {
    if (bizType) {
      save(history, {bizType});
    }
  }, [bizType, history]);

  const addBizType = (value: string) => {
    if (isDesktop) {
      if (!isIncludesWithoutSpace(value)) {
        bizTypes.unshift(value);
        setRequest({ bizType: bizTypes.join('//') });
      }

      setRecommends([]);
      refInput.current!.value = '';
      return;
    }
    setRequest({ bizType: value });
  };

  const removeBizType = (value: string) => {
    _.remove(bizTypes, biz => biz === value);
    setRequest({ bizType: bizTypes.join('//') });
  };

  const checkRecommend = (value: string) => {
    if (isDesktop) {
      setKeyInValue(value);
      if (value === '') {
        setRecommends([]);
        return;
      }

      setRecommends(
        BIZ_TYPE_LIST
          .sort()
          .filter(biz => (
                _.includes(trimRegex(biz), trimRegex(value))
                || Hangul.search(trimRegex(biz), trimRegex(value)) === 0
              )
              && !_.includes(bizTypes, trimRegex(biz))
          )
      );

      return;
    }
    setRequest({ bizType: value });
  };

  const isIncludesWithoutSpace = (value: string) => {
    if (bizTypes.length === 0) {
      return false;
    }
    return bizTypes.filter((bizType) => trimRegex(bizType).includes(trimRegex(value))).length > 0;
  };

  const trimRegex = (value: string) => value.replace(/ /g, '');

  const onClickNext = () => goNext(history, {bizType});

  const isDisabled = bizType === '';

  const keyEvent = (e: any) => {
    if (e.target == null) {
      return;
    }
    e.persist();
    e.stopPropagation();
    switch (e.keyCode) {
      case 40: // 'ArrowDown'
        if ((recommends.length - 1) !== select) {
          setSelect(Math.min(select + 1, recommends?.length - 1));
          scrollDown();
        }
        return;
      case 38: // 'ArrowUp'
        setSelect(Math.max(select - 1, 0));
        scrollUp();
        return;
      case 13: // Enter
        addBizType(refInput.current!.value);
        setKeyInValue('');
        if (!isDesktop) {
          setIsFocus(false);
          footerRef.current?.clickNext();
        }
        return;
      case 27: // Escape
        if (!isDesktop) {
          setIsFocus(false);
          refInput.current!.blur();
        }
        return;
      case 37: // ArrowRight, ArrowLeft
      case 39:
        return;
      default:
        setSelect(-1);
        return;
    }
  };

  const scrollDown = () => {
    const scrollBox = document.getElementById('keyword-box');
    let selectItem = document.getElementById(`item-${select}`);
    if (select !== -1) {
      scrollBox?.scrollTo(0, selectItem?.offsetTop ?? 0);
    }
  };

  const scrollUp = () => {
    const scrollBox = document.getElementById('keyword-box');
    let selectItem = document.getElementById(`item-${select - 2}`);
    scrollBox?.scrollTo(0, selectItem?.offsetTop ?? 0);
  };

  const addStyleToMatchWord = (value: string, keyword: string) => {
    const reg = createSearchRegex(keyword);
    value = value.replace(reg, `<span style='color: #FF5D00'>$&</span>`);
    return {__html: value};
  };

  const handleBlur = () => footerRef.current?.hideMessage();

  const styles = Styles({isMobile, isTablet});
  return (
    <div
      className={css(
        stepViewAnimationStyle,
        animationStyle(0, 0).popUp
      )}
    >
      <div className={css(styles.description)} onClick={() => setIsFocus(false)}>
        사업종류를 알고싶어요 <span role={'img'} aria-label={'search'}>🔍</span>
      </div>
      <RocketInput
        id={'request_jobType'}
        addStyle={styles.space}
        placeholder={'업종을 자유롭게 적어주세요.(ex. 제조업 등)'}
        onFocus={() => {
          setIsFocus(true);
          onScrollUp();
          setSelect(-1);
        }}
        value={!isDesktop ? bizType : undefined}
        onChange={checkRecommend}
        onKeydown={keyEvent}
        refObject={refInput}
        onBlur={handleBlur}
      >
        <>
          {(bizTypes.length === 0) && (
            <div
              className={css(
                (refInput.current?.value !== '' && isFocus) && styles.hide,
                styles.chat_box,
              )}
            >
              {
                isDesktop ?
                  <ChatBox className={css(styles.chat_box__image)}/>
                  :
                  <ChatBoxMobile className={css(styles.chat_box__image)}/>
              }
              <div className={css(styles.chat_box__content)}>
                업종이 정해지지 않았다면 <strong>‘미정’</strong>으로 적어주세요<DesktopBr/>
                {
                  isDesktop && (
                    <>
                      또한 여러개의 업종 추가가 가능합니다
                    </>
                  )
                }
                <span role={'img'} aria-label={'search'}> 🙂</span>
              </div>
            </div>
          )}
          <div className={css(styles.biztype_box)}>
            {bizTypes.map((biz, index) => (
              <div className={css(styles.biztype_item)} key={'biz-' + index}>
                <span
                  className={css(styles.biztype_title)}
                >
                  {biz}
                </span>
                <Close
                  className={css(styles.close)}
                  onClick={() => removeBizType(biz)}
                />
              </div>
            ))}
          </div>
          <div
            className={css(
              styles.auto_box,
              (!isFocus || keyInValue.length === 0) && styles.hide
            )}
          >
            <div
              id={'keyword-box'}
              className={css(
                styles.auto_box_list,
                (!isFocus || recommends.length === 0) && styles.hide
              )}>
              {recommends.map((item, index) => (
                <div
                  id={'item-' + index}
                  className={css(
                    styles.item,
                    select === index && styles.item_select,
                  )}
                  onClick={() => addBizType(item)}
                  key={'item-' + index}
                >
                  <span
                    id={'data-' + index}
                    className={css(styles.item__shortLine, item.length > 24 && styles.item__longLine)}
                    dangerouslySetInnerHTML={addStyleToMatchWord(item, keyInValue)}
                  />
                </div>
              ))}
            </div>
            <div className={css(styles.auto_box_hr)}/>
            <div
              id={'type_plus_button'}
              className={css(styles.auto_box_add_btn)}
              onClick={() => addBizType(refInput.current!.value)}
            >
              ‘{keyInValue}’ 추가하기
            </div>
          </div>
        </>
      </RocketInput>
      <RocketProgressFooter
        ref={footerRef}
        progress={isDesktop ? '2' : '3'}
        onClickNext={onClickNext}
        isDisabled={isDisabled}
      />
    </div>
  );
});

const Styles = ({isMobile, isTablet}: { isMobile: boolean, isTablet: boolean }) => CreateStyle({
  description: {
    fontSize: '16px/18px@18px',
    lineHeight: '30px/30px@31px',
    marginBottom: '36px/45px@30px',
    color: isMobile ? '#242424' : rocketContentBlack,
  },
  chat_box: {
    position: 'absolute',
    bottom: '/-16px',
    transform: '/translateY(100%)',

    width: '100%/566px',
    height: '78px/84px',

    fontSize: '15px/12px',
    lineHeight: '23px/18px',
    color: '#333333',

    filter: 'drop-shadow(4px 14px 30px rgba(0, 0, 0, 0.06))',
    display: 'flex',
    alignItems: 'center',
    justifyContent_: 'center/',
    marginLeft: '/13px',
    marginTop: '10px/',
  },
  chat_box__image: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -2,
    background: 'transparent',

    width: '100%/287px',
    height: '68px/68px',

    userSelect: 'none',
    pointerEvents: 'none',
  },
  chat_box__content: {
    width: '309px/235px',
    height: '23px/38px',

    marginLeft: '/19px',
    margin: '0 auto/',
    marginTop: '1px/',
    whiteSpace: 'nowrap',
    letterSpacing: '-0.02em',
  },
  chat_box_font_ex: {
    color: 'gray',
  },
  space: {
    marginBottom: '101px/146px',
  },
  auto_box: {
    position: 'absolute',
    bottom: '/-12px',
    left: '50%',
    transform: 'translate(-50%, 100%)',
    width: '/330px',
    maxHeight: '/296px',

    background: '#F8F8F8',
    borderRadius: '/15px',
    zIndex: 5,
    transition: 'opacity .3s',

    fontFamily: spoqa,
    color: '#333333',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  auto_box_list: {
    width: '/306px',
    maxHeight: '/193px',
    background: 'inherit',
    marginBottom: '/12px',
    marginTop: '/21px',

    overflowY_: 'overlay',
    '::-webkit-scrollbar': {
      width: '/4px',
      borderRadius: '/2px',
      paddingRight: '/12px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#DADADA',
      borderRadius: '/2px',
    },
    '::-webkit-scrollbar-track': {
      borderRadius: '/2px',
      background: 'transparent',
      ':horizontal': {
        background: 'transparent',
      },
    },
  },
  auto_box_hr: {
    width: '/294px',
    borderTop: '1px solid #EBEBEB',
  },
  auto_box_add_btn: {
    width: '/306px',
    height: '/42px',
    borderRadius: '10px',

    fontSize: '/14px',
    fontWeight: 'bold',
    lineHeight: '/24px',
    color: rocketOrange,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    margin: '/10px 0',

    ':hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(255, 93, 0, 0.04)',
    }
  },
  hide: {
    opacity: 0,

    userSelect: 'none',
    pointerEvents: 'none',
  },
  item: {
    fontSize: '/14px',
    padding: '/11px',
    cursor: 'pointer',
    letterSpacing: '-0.02em',
    lineHeight: '/24px',

    display: 'flex',
    alinItems: 'center',

    ':hover': {
      background: 'rgba(235, 235, 235, 0.6)',
      borderRadius: '/10px',
    }
  },
  item_select: {
    background: 'rgba(235, 235, 235, 0.6)',
    borderRadius: '/10px',
  },
  item__shortLine: {
    width: '/300px',
    wordBreak: 'keep-all',
    lineHeight: '/24px',
  },
  item__longLine: {
    lineHeight: '/22px',
  },
  biztype_box: {
    position: 'absolute',
    top: '/70px',
    left: 0,

    display: 'flex',
    flexWrap: 'wrap',

    width: '/380px',

    overflowY_: 'overlay',
    maxHeight: '/135px',

    '::-webkit-scrollbar': {
      width: '/8px',
      borderRadius: '/15px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#DADADA',
      borderRadius: '/15px',
    },
    '::-webkit-scrollbar-track': {
      borderRadius: '/15px',
      background: 'transparent',
      ':horizontal': {
        background: 'transparent',
      },
    },
  },
  biztype_item: {
    padding: '/0 16px 0 18px',
    width: 'max-content',
    height: '/40px',

    border: '/1px solid #EDEDED',
    borderRadius: '/82px',

    fontSize: '/14px',
    lineHeight: '/18px',
    letterSpacing: '/-0.02em',
    color: '#333333',
    background: 'white',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    marginRight: '/10px',
    marginBottom: '/5px',

    position: 'relative',
  },
  biztype_title: {
    paddingRight: '/20px',
  },
  close: {
    position: 'absolute',
    right: '/12px',

    width: '/17px',
    height: '/17px',
    marginLeft: '/6px',
    cursor: 'pointer',
  }
}, isMobile, isTablet);
