import axios from 'axios';

import { BuildConfirmProps, KakaoMessageSender } from '../domain/KakaoMessageSender';
import { getFormalPhone, getFormalTime } from '../helper/Util';
import { ConfirmMessageNotSendedError } from '../store/ConfirmMessageNotSendedError';



export class ServerKakaoMessageSender implements KakaoMessageSender {
  constructor(
    private readonly sendMessageBaseUrl?: string,
  ) {
  }

  async sendConsultantMessage(targetPhone: string) {
    const phone = getFormalPhone(targetPhone);

    await axios.post(
      this.sendMessageBaseUrl + '/consultant',
      {phone},
    );
  };

  async sendConfirmRequestMessage(name: string, targetPhone: string, code?: string) {
    const phone = getFormalPhone(targetPhone);
    const time = getFormalTime();

    try {
      await axios.post(
        this.sendMessageBaseUrl + '/confirm',
        {phone, name, time, code},
      );
    } catch (e) {
      throw new ConfirmMessageNotSendedError(this.sendMessageBaseUrl + '/confirm');
    }
  };

  async sendConfirmRequestMessageForBuild(
    {
      targetPhone,
      name,
      email,
      corporateName,
      companyType,
      corporationType,
      place,
      capitalStock
    }: BuildConfirmProps) {
    const phone = getFormalPhone(targetPhone);
    const time = getFormalTime();

    try {
      await axios.post(
        this.sendMessageBaseUrl + '/confirm',
        {phone, name, time, email, corporateName, companyType, corporationType, place, capitalStock},
      );
    } catch (e) {
      throw new ConfirmMessageNotSendedError(this.sendMessageBaseUrl + '/confirm');
    }
  };

  async sendConfirmRequestMessageForPartnership(name: string, targetPhone: string) {
    const phone = getFormalPhone(targetPhone);
    const time = getFormalTime();

    try {
      await axios.post(
        this.sendMessageBaseUrl + '/partnership',
        {phone, name, time},
      );
    } catch (e) {
      throw new ConfirmMessageNotSendedError(this.sendMessageBaseUrl + '/confirm');
    }
  };
}
