import KakaoPopUpRocketStyle from './KakaoPopUpRocketStyle';
import React, { useEffect } from 'react';
import { css } from 'aphrodite';
import { useHistory } from 'react-router-dom';
import { IHistory, kakaoPage } from '../../../shared/presentation/HistoryHelper';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useInjection } from '../../../shared/presentation/InversifyProvider';
import { KAKAO_SERVICE, kakaoPhoneState, KakaoService } from '../../../shared/store/KakaoStore';
import { SCRIPT_LOGGER, ScriptLogger } from '../../../shared/domain/ScriptLogger';
import { isLoadingState } from '../../../shared/presentation/LoadingFrame';

export function KakaoCheckRocket() {
  const styles = KakaoPopUpRocketStyle(false);
  const history = useHistory<IHistory>();
  const phone = useRecoilValue(kakaoPhoneState);
  const setIsLoading = useSetRecoilState(isLoadingState);
  const kakaoService = useInjection<KakaoService>(KAKAO_SERVICE);
  const scriptLogger = useInjection<ScriptLogger>(SCRIPT_LOGGER);

  useEffect(() => {
    if (phone === '') {
      history.goBack();
      return
    }
    scriptLogger.sendPathApproachedLog('/kakao_check')
  }, [])

  const sendRequest = async () => {
    setIsLoading(true);
    try {
      await kakaoService.sendRequest(phone);
    } catch (e) {
      alert('간편신청에 실패했습니다.');
    } finally {
      setIsLoading(false);
    }

    scriptLogger.sendPathApproachedLog('/kakao_success');
    scriptLogger.sendRequestOccuredLog();
    history.push(kakaoPage(2))
  };

  return (
    <React.Fragment>
      <div className={css(styles.checkPhone, styles.check_margin_bottom)}>
        {phone}
      </div>
      <div className={css(styles.kakao_comment)}>
        휴대폰 번호가 맞으시면 아래의 확인 버튼을 눌러주세요
      </div>
      <button
        className={css(styles.button, styles.button_margin_top)}
        type='button'
        onClick={sendRequest}
        id={'kakao_check_button'}
      >
        확인
      </button>
      <button
        className={css(styles.button, styles.button_check, styles.button_margin_top)}
        type='button'
        onClick={() => history.goBack()}
        id={'modify_number'}
      >
        휴대폰 번호 재입력
      </button>
    </React.Fragment>
  );
}
