import React from 'react';
import { css } from 'aphrodite';
import { Link } from 'react-router-dom';

import { ReactComponent as MenuIcon } from '../assets/svgs/menu.svg';
import { HeaderButtonStyles } from '../../shared/presentation/header/HeaderButtonStyle';
import { ButtonsStyles } from './CustomHeaderStyles';
import { menuPage } from '../../shared/presentation/HistoryHelper';

interface Props {
  baseColor?: string,
  isMobile?: boolean,
  isTablet?: boolean,
}

export function MenuButton({baseColor, isMobile = false, isTablet = false}: Props) {
  const styles = HeaderButtonStyles({
    isMobile: isMobile,
    isTop: false,
    baseColor: baseColor ?? 'black',
  });
  const custom = ButtonsStyles(
    isMobile,
    isTablet,
    baseColor,
  );
  return (
    <>
      {
        (isMobile || isTablet) && (
          <div
            className={css(styles.button, custom.button__custom, custom.button__menu)}
            id={'menu_button'}
          >
            <Link
              to={menuPage}>
              <MenuIcon
                className={css(custom.button__icon)}
                style={{padding: '1px'}}
              />
            </Link>
          </div>
        )
      }
    </>
  );
}
