import React from 'react';
import { Helmet } from 'react-helmet';

interface HelmetProps {
  favicon?: string;
  project: string;
}

class ReactHelmet extends React.Component<HelmetProps> {
  render() {
    const { favicon, project } = this.props;
    // const originPath = window?.location?.origin;
    return (
      <Helmet>
        <meta name="google-site-verification" content={process.env.REACT_APP_GOOGLE_VERIFICATION}/>
        <meta name='keywords' content={process.env.REACT_APP_KEYWORDS}/>
        <meta property='og:title' content={process.env.REACT_APP_TITLE}/>
        <meta property='og:image' content={favicon}/>
        <meta property='og:site_name' content=''/>
        <meta property='og:description' content={process.env.REACT_APP_DESCRIPTION}/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        {/*<meta name="theme-color" content="#000000" />*/}

        <meta name='twitter:title' content={process.env.REACT_APP_TITLE}/>
        <meta name='twitter:description' content={process.env.REACT_APP_DESCRIPTION}/>
        <meta name='twitter:image' content={favicon}/>
        <meta name='twitter:card' content='summary'/>


        <link rel='preconnect' href={process.env.REACT_APP_SMARTLOG_HOST}/>

        <link rel='icon' href={`${process.env.PUBLIC_URL}/${project}/favicon.ico`}/>
        <link rel='shortcut icon' href={`${process.env.PUBLIC_URL}/${project}/favicon.ico`}/>
        <link rel='manifest' href={`${process.env.PUBLIC_URL}/${project}/manifest.json`}/>
        <link rel='apple-touch-icon' href={`${process.env.PUBLIC_URL}/${project}/logo192.png`}/>
        <link rel='apple-touch-icon-precomposed' href={`${process.env.PUBLIC_URL}/${project}/logo192.png`}/>
        {/*{!isIOS && process.env.REACT_APP_COMPANY_SITE_URL && (*/}
        {/*  <script type="text/javascript">*/}
        {/*    {`location.href="${originPath}#Intent;scheme=http;package=com.android.chrome;end"`}*/}
        {/*    /!*{`location.href="intent://${'192.168.0.198:3000'}#Intent;scheme=http;package=com.android.chrome;end"`}*!/*/}
        {/*  </script>*/}
        {/*)}*/}
        {/*{isIOS && isMobileOnly && process.env.REACT_APP_COMPANY_SITE_URL && (*/}
        {/*  <script type="text/javascript">*/}
        {/*    {`location.href="${originPath}/ios#end"`}*/}
        {/*  </script>*/}
        {/*)}*/}
        {/*<script> location.href={`intent://${process.env.REACT_APP_COMPANY_SITE_URL}#Intent;scheme=http;package=com.android.chrome;end`} </script>*/}
        {/*// Smartlog Script*/}
        {/*<script type="text/javascript">*/}
        {/*  {`var hpt_info = { _account: '${process.env.REACT_APP_SMARTLOG_ID}' };`}*/}
        {/*</script>*/}
        {/*<script type="text/javascript" charSet='utf-8' src={`//${process.env.REACT_APP_SMARTLOG_HOST}/smart.js`} />*/}
        {process.env.MOBILE &&
        <script type="text/javascript" src="cordova.js"/>
        }
        {/*<script type="text/javascript">*/}
        {/*  {`*/}
        {/*    var hpt_info = { _account: '${process.env.REACT_APP_SMARTLOG_ID}' };*/}
        {/*    var smtg_sc = document.createElement('script');*/}
        {/*    smtg_sc.type = 'text/javascript';*/}
        {/*    smtg_sc.async = true;*/}
        {/*    smtg_sc.charset = 'utf-8';*/}
        {/*    smtg_sc.src =*/}
        {/*    ('https:' == document.location.protocol ? 'https://' : 'http://') +*/}
        {/*    '${process.env.REACT_APP_SMARTLOG_HOST}/smart.js';*/}
        {/*    var smtg_script = document.getElementsByTagName('script')[0];*/}
        {/*    smtg_script.parentNode.insertBefore(smtg_sc, smtg_script);*/}
        {/*  `}*/}
        {/*</script>*/}
        <noscript>
          {`<img src="//${process.env.REACT_APP_SMARTLOG_HOST}/smart_bda.php?_account=${this.splitSmartLogId(process.env.REACT_APP_SMARTLOG_ID)}" style="display:none;width:0;height:0;" border="0" alt="conversion_script"/>`}
        </noscript>
      </Helmet>
    );
  }

  splitSmartLogId = (id: string | undefined) => {
    if (id === undefined) {
      return '';
    }
    return id.replace('UHPT-', '');
  };
}

export default ReactHelmet;
