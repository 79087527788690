import * as _ from 'lodash';

import { PartnerForm, SharedPartnerField, SharedPartnerForm } from '../airtable/PartnerForm';

export class AddPartnershipRequestDto extends SharedPartnerForm implements PartnerForm {
  public readonly address = '';

  get fields(): SharedPartnerField {
    return _.merge(this.getFields(), {
      COMMENT: `${this.email} / ${this.address} / ${this.question}`,
    });
  }
}
