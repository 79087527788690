import { StyleSheet } from 'aphrodite';

import { spoqa } from '../../../shared/css/css-utils';
import { center } from '../../../shared/css/styles';

const KakaoPopUpRocketStyle = (isMobile: boolean) => StyleSheet.create({
  popup: {
    width: '455px',
    borderRadius: '10px',
    backgroundColor: '#ffe900',

    position: 'relative',

    paddingTop: '44px',
    paddingLeft: '40px',
    paddingBottom: '40px',
  },
  close_button: {
    position: 'absolute',
    top: 28,
    right: 28,

    width: 26,
    height: 26,
    fill: '#3a1e0b',

    cursor: 'pointer',
  },
  firstLine: {
    display: 'flex',

    fontFamily: spoqa,
    fontWeight: 500,
    fontSize: '28px',
    color: '#3a1e0b',

    marginBottom: 30,
  },
  kakaoImage: {
    width: '40px',
    height: '40px',

    fill: '#3a1e0b',

    marginRight: '11px',
  },
  inputStyle: {
    width: '370px',
    height: '60px',
    borderRadius: '10px',
  },
  inputBox: {
    position: 'relative',
    width: '370px',
    height: '60px',
    fontSize: '14px',
    marginBottom: '15px',
  },
  input: {
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    padding: '0 20px',
    '::placeholder': {
      color: '#3a1e0b',
      fontFamily: spoqa,
      fontWeight: 500,
    }
  },
  input_error: {
    border: 'solid 1px #ff5c5c',
  },
  error: {
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    width: '100%',
    textAlign: 'right',
    padding: '0 27px',

    color: '#ff5c5c',
    fontSize: '13px',

    pointerEvents: 'none',
  },
  kakao: {
    width: 40,
    height: 40,
    fill: '#3a1e0b',
  },
  kakao_comment: {
    fontFamily: spoqa,
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: 1.85,
    color: '#3a1e0b',

    marginLeft: '3px',
  },
  kakao_margin_bottom: {
    marginBottom: '42px',
  },
  button: {
    ...center,
    width: '370px',
    height: '60px',
    borderRadius: '10px',
    border: 'solid 1px #3a1e0b',
    backgroundColor: 'transparent',

    fontFamily: spoqa,
    fontWeight: 500,
    fontSize: '14px',
    color: '#3a1e0b',

    cursor: 'pointer',

    ':hover': {
      backgroundColor: '#3a1e0b',
      color: '#ffe900',
      transition: '0.3s',
    },
  },
  button_margin_top: {
    marginTop: '16px',
  },
  button_check: {
    backgroundColor: '#3a1e0b',
    color: '#ffe900',
    ':hover': {
      backgroundColor: '#ffe900',
      color: '#3a1e0b',
      transition: '0.3s',
    },
  },
  checkPhone: {
    fontFamily: spoqa,
    fontWeight: 500,
    fontSize: '28px',
    color: '#3a1e0b',
  },
  check_margin_bottom: {
    marginBottom: '16px',
  },
  finish_font_size: {
    fontSize: '14px',
  },
  finish_margin_bottom: {
    marginBottom: '40px',
  },
});

export default KakaoPopUpRocketStyle;
