import { StyleDeclaration, StyleDeclarationMap, StyleDeclarationValue, StyleSheet } from 'aphrodite';
import * as _ from 'lodash';

import { CSSProperties } from 'aphrodite/typings/css-properties';
import { MOBILE_SIZE, TABLET_SIZE } from '../../helper/Util';

const createCalc = (value: number, window: number) => (value / window * 100).toFixed(1) + 'vw';

interface IOptions {
  width?: number,
  mobileWidth?: number,
  tabletWidth?: number,
  isNoRatio?: boolean,
  isBeta?: boolean,
}

export function CreateSingle(
  style: CSSProperties | StyleDeclarationMap,
  isMobile: boolean,
  isTablet?: boolean,
  options?: IOptions,
) {
  const edited = _.chain(style).mapKeys((value, key) => key.replace('_', '')).mapValues((item, key) => {
    if (_.isString(item) && item.split('/').length === 2) {
      return getDeviceValue(item, {
        isTablet,
        isMobile,
        ...options,
        isNotViewWidth: key.indexOf(('min')) !== -1,
      });
    }
    return item;
  }).value();
  return StyleSheet.create({ default: edited as CSSProperties }).default;
}

export function CreateBetaStyle<T>(
  styles: StyleDeclaration<T>,
  options?: IOptions
): { [K in keyof T]: StyleDeclarationValue } {
  return CreateStyle(styles, false, false, { ...options, isBeta: true, isNoRatio: true });
}

export function CreateStyle<T>(
  styles: StyleDeclaration<T>,
  isMobile: boolean,
  isTablet?: boolean,
  options?: IOptions
): { [K in keyof T]: StyleDeclarationValue } {
  if (options?.isBeta) {
    const result = _.mapValues(styles, style => mapStyles02(style, { options }));
    return StyleSheet.create(result);
  }

  const result = _.mapValues(styles, style => {
    return mapStyles(style as any, { isMobile, isTablet, options }) as CSSProperties;
  });
  return StyleSheet.create(result);
}

function mapStyles02(style: any, input: {
  options?: IOptions,
}): any {
  const { options } = input;
  const mobileSetting: { [key: string]: any } = {};
  const tabletSetting: { [key: string]: any } = {};
  const result = _.chain(style as Object)
    .mapValues((item, key) => {
      if (_.isString(item) && item.indexOf('/') !== -1) {
        const splits = item.split('/');
        const tabletSplits = item.split('@');
        mobileSetting[key.replace('_', '')] = pxChange({
          key, value: splits[0], width: mapSize(DevType.Mobile, options),
        });
        tabletSetting[key.replace('_', '')] = pxChange({
          key, value: tabletSplits[1] ?? splits[0], width: mapSize(DevType.Tablet, options)
        });

        return pxChange({
          key, value: splits[1].split('@')[0], width: mapSize(DevType.Desktop, options)
        });
      }

      if (key.startsWith(':') && _.isObject(item)) {
        return mapStyles02(item, { options });
      }
      return item;
    })
    .merge({
      [`@media ( max-width: ${process.env.REACT_APP_TABLET_WIDTH}px )`]: tabletSetting,
      [`@media ( max-width: ${process.env.REACT_APP_MOBILE_WIDTH}px )`]: mobileSetting,
    })
    .mapKeys((value, key) => key.replace('_', ''))
    .value();
  return result;
}

enum DevType {
  Mobile,
  Tablet,
  Desktop,
}

function mapSize(type: DevType, options?: IOptions): number {
  switch (type) {
    case DevType.Mobile:
      return options?.mobileWidth ?? parseInt(process.env.REACT_APP_MOBILE_WIDTH ?? '375');
    case DevType.Tablet:
      return options?.tabletWidth ?? parseInt(process.env.REACT_APP_TABLEY_WIDTH ?? '768');
    case DevType.Desktop:
    default:
      return options?.width ?? parseInt(process.env.REACT_APP_WIDTH ?? '1920');
  }
}

function pxChange({ key, value, width }: { key: string, value: string, width: number }): string {
  const pxRegex = /-?\d*[.\d]*px/g;
  if (value === '') {
    return 'inherit';
  }
  if (pxRegex.test(value)) {
    return _.replace(value, pxRegex, value => createCalc(Number(value.replace('px', '')), width));
  }
  return value;
}

function mapStyles(style: any, input: {
  isMobile: boolean,
  isTablet?: boolean,
  options?: IOptions,
}): any {
  const { isMobile, isTablet, options } = input;
  return _.chain(style as Object)
    .mapValues((item, key) => {
      if (_.isString(item) && item.split('/').length === 2) {
        return getDeviceValue(item, {
          isTablet,
          isMobile,
          ...options,
          isNotViewWidth: _.startsWith(key, 'min'),
        });
      }

      if (key.startsWith(':') && _.isObject(item)) {
        return mapStyles(item, { isMobile, isTablet, options });
      }
      return item;
    })
    .mapKeys((value, key) => key.replace('_', ''))
    .value();
}


export function getDeviceValue(item: string, options: {
  isMobile?: boolean,
  isTablet?: boolean,
  isNotViewWidth?: boolean,
  mobileWidth?: number,
  tabletWidth?: number,
  width?: number,
  isNoRatio?: boolean,
} = {}) {
  const { isMobile, isTablet, isNotViewWidth, isNoRatio } = options;
  const width =
    isMobile
      ? options?.mobileWidth ?? parseInt(process.env.REACT_APP_MOBILE_WIDTH ?? '375')
      : isTablet
        ? options?.tabletWidth ?? parseInt(process.env.REACT_APP_TABLEY_WIDTH ?? '768')
        : options?.width ?? parseInt(process.env.REACT_APP_WIDTH ?? '1920');
  const splits = item.split('/');
  const tabletSplits = item.split('@');
  const selected =
    isMobile ? splits[0]
      : isTablet ? tabletSplits[1] ?? splits[0]
        : splits[1].split('@')[0];
  if (selected === '') {
    return undefined;
  }
  if (isNotViewWidth) {
    return selected;
  }

  const pxRegex = /-?\d*[.\d]*px/g;
  if (pxRegex.test(selected) && !isNoRatio) {
    return _.replace(selected, pxRegex, value => createCalc(Number(value.replace('px', '')), width));
  }

  return selected;
}
