import { inject, injectable } from 'inversify';
import 'reflect-metadata';

import { SCRIPT_LOGGER, ScriptLogger } from '../domain/ScriptLogger';

export interface VideoPageManager {
  isShowVideoPopup(): boolean;
  showVideoPopup(): void;
  closePopups(): void;
}

const isChromeInIPhone = (): boolean => {
  // @ts-ignore
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  // @ts-ignore
  const isOpera = typeof window.opr !== 'undefined';
  const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
  const isIOSChrome = winNav.userAgent.match('CriOS');
  if (isIOSChrome) {
    return true;
  }
  if (
    isChromium !== null
    && typeof isChromium !== 'undefined'
    && vendorName === 'Google Inc.' && !isOpera && !isIEedge
  ) {
    return false;
  }
  return false;
};

export const LOCATION_SERVICE = Symbol('LOCATION_SERVICE');

@injectable()
export class LocationService {
  @inject(SCRIPT_LOGGER) scriptLogger!: ScriptLogger;

  callToService() {
    window.location.href = `tel:${process.env.REACT_APP_SERVICE_CENTER_NUMBER}`;
    this.scriptLogger.sendRequestOccuredLog();
    this.scriptLogger.sendPathApproachedLog('/phone');
  }

  callToNumber(number: string | undefined) {
    if (number != null) {
      window.location.href = `tel:${number}`;
      this.scriptLogger.sendRequestOccuredLog();
      this.scriptLogger.sendPathApproachedLog('/phone');
    }
  }

  openKakaoMobilePage() {
    if (isChromeInIPhone()) {
      window.open(process.env.REACT_APP_KAKAO_MOBILE_PAGE_URL, '_blank');
      return;
    }
    window.open(process.env.REACT_APP_KAKAO_MOBILE_PAGE_URL + '/chat', '_blank');

    this.scriptLogger.sendRequestOccuredLog();
    this.scriptLogger.sendPathApproachedLog('/kakao_mobile');
  }


  openBlogPage() {
    if (process.env.REACT_APP_BLOG_PAGE_URL === '' || process.env.REACT_APP_BLOG_PAGE_URL == null) {
      return;
    }
    window.open(process.env.REACT_APP_BLOG_PAGE_URL);
  }

  openFacebookPage = () => {
    if (process.env.REACT_APP_FACEBOOK_PAGE_URL === '' || process.env.REACT_APP_FACEBOOK_PAGE_URL == null) {
      return;
    }
    window.open(process.env.REACT_APP_FACEBOOK_PAGE_URL);
  };
}

export const openPrivacyPolicy = () => window.open('/privacy_policy');
export const openTermsOfService = () => window.open('/terms_of_service');


// export class LocationStore {
//   constructor(
//     private readonly screenStore: ScreenStore,
//     private readonly kakaoMobilePageUrl: string | undefined,
//     private readonly blogPageUrl: string | undefined,
//     private readonly facebookPageUrl: string | undefined,
//     private readonly logger: ScriptLogger,
//     private readonly pageManager: VideoPageManager,
//   ) {
//     window.addEventListener("keydown", this.escFunction);
//   }
//
//   @observable
//   isAdsPopup: boolean = true;
//
//   @action
//   setAdsPopup = (isAdsPopup: boolean) => {
//     this.isAdsPopup = isAdsPopup;
//   }
//
//   isMain(): boolean {
//     return history.location.pathname === '/';
//   }
//
//   isPartnershipPage(): boolean {
//     return history.location.pathname === '/partnership';
//   }
//
//   push(path: string) {
//     history.push(path);
//   }
//
//   isShowMenu = (): boolean => history.location.state?.isPopUpMenu === true;
//
//   isPrivacyPolicy(): boolean {
//     return history.location.pathname === '/privacy_policy';
//   };
//
//   isTermsOfService(): boolean {
//     return  history.location.pathname === '/terms_of_service';
//   }
//
//   isShowVideo = (): boolean => {
//     return this.pageManager.isShowVideoPopup();
//   }
//
//   isRequestPage = () => {
//     return history.location.pathname === '/request';
//   }
//
//   closePopups = () => {
//     this.pageManager.closePopups();
//   }
//
//   showMenu = () => {
//     history.push({
//       state: {
//         isPopUpMenu: true,
//       }
//     });
//   }
//
//   showVideo = () => {
//     this.pageManager.showVideoPopup();
//   }
//
//   openKakaoMobilePage = () => {
//     if (this.isChromeInIPhone()) {
//       window.open(this.kakaoMobilePageUrl);
//       return;
//     }
//     window.open(this.kakaoMobilePageUrl + '/chat');
//
//     if (!this.isPartnershipPage()) {
//       this.logger.sendRequestOccuredLog()
//       this.logger.sendPathApproachedLog('/kakao_mobile');
//     }
//   };
//
//   openBlogPage = () => {
//     if (this.blogPageUrl === '' || this.blogPageUrl == null) {
//       return;
//     }
//     window.open(this.blogPageUrl)
//   }
//
//   openFacebookPage = () => {
//     if (this.facebookPageUrl === '' || this.facebookPageUrl == null) {
//       return;
//     }
//     window.open(this.facebookPageUrl)
//   }
//
//   moveToHome = () => {
//     document.body.style.overflow = 'auto';
//     if (this.isMain()) {
//       this.scroll('main');
//       this.closePopups();
//       return;
//     }
//     history.push('/');
//     this.screenStore.scrollTo(0, 0);
//   };
//
//   moveTo = (path: string) => {
//     document.body.style.overflow = 'auto';
//     if (history.location.pathname === path) {
//       this.scroll('main');
//     } else {
//       this.screenStore.scrollTo(0, 0);
//     }
//     history.push(path);
//   }
//
//   openPartnership = () => {
//     if (!this.isPartnershipPage()) {
//       window.open('/partnership');
//     } else {
//       this.closePopups();
//     }
//   };
//
//   openPrivacyPolicy = () => {
//     window.open('/privacy_policy');
//   };
//
//   openTermsOfService = () => {
//     window.open('/terms_of_service');
//   };
//
//   openTransbiz = () => {
//     window.open('https://k-transbiz.org/');
//   };
//
//   openStartbiz = () => {
//     window.open('https://k-startbiz.org/');
//   }
//
//   private isChromeInIPhone = () => {
//     // @ts-ignore
//     const isChromium = window.chrome;
//     const winNav = window.navigator;
//     const vendorName = winNav.vendor;
//     // @ts-ignore
//     const isOpera = typeof window.opr !== 'undefined';
//     const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
//     const isIOSChrome = winNav.userAgent.match('CriOS');
//     if (isIOSChrome) {
//       return true;
//     }
//     if (
//       isChromium !== null
//       && typeof isChromium !== 'undefined'
//       && vendorName === 'Google Inc.' && !isOpera && !isIEedge
//     ) {
//       return false;
//     }
//     return false;
//   }
//
//   scroll(path: string) {
//     this.closePopups();
//     const offset = getOffset(path);
//     this.screenStore.scrollWith({
//       behavior: 'smooth',
//       top: offset,
//     });
//   }
//
//   escFunction = (e: any) => {
//     if(e.key === "Escape"){
//       this.closePopups();
//     }
//   }
// }
