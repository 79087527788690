import React from 'react';
import { css } from 'aphrodite';
import { useRecoilValue } from 'recoil';
import { Link } from 'react-router-dom';

import { ReactComponent as Kakao } from '../../shared/presentation/icons/kakao.svg';
import { ButtonsStyles } from './CustomHeaderStyles';
import { HeaderButtonStyles } from '../../shared/presentation/header/HeaderButtonStyle';
import { totalScreenState } from '../../shared/presentation/MobileFrame';
import { kakaoPage } from '../../shared/presentation/HistoryHelper';

interface Props {
  baseColor?: string,
}

export function KakaoButton({baseColor}: Props) {
  const {isMobile, isTablet, isDesktop} = useRecoilValue(totalScreenState);
  const styles = HeaderButtonStyles({
    isMobile,
    isTop: false,
    baseColor: baseColor ?? 'black',
  });
  const custom = ButtonsStyles(isMobile, isTablet);
  return (
    <>
      {
        isDesktop && (
          <Link
            className={css(styles.button, custom.button__custom, custom.button__kakao)}
            id={'kakao_request_button'}
            to={kakaoPage()}
          >
            <Kakao className={css(custom.button__icon)}/>
            <p className={css(styles.text, custom.button__kakaoText)}>카카오톡 상담</p>
          </Link>
        )
      }
    </>
  );
}
